import React from "react";
import { get } from "lodash";
import moment from "moment";
import withFilter from "../withFilter";
import List from "../../components/List";
import Filter from "./filter";
import withQuery from "../../components/withQuery";
import ListHead from "../../components/ListHead";

const ContractRow = withQuery(
  ({ children, template, references, status = "Draft", updatedAt }) => (
    <li>
      <div>{get(references, "contractorName", "--")}</div>
      <div>{template}</div>
      <div>{status}</div>
      <div>{updatedAt ? moment(updatedAt).format("LLL") : "--"}</div>
      {children}
    </li>
  ),
  {
    namespace: "contract",
    queryKey: "getContracts",
  }
);

export default withFilter(
  (props) => (
    <List>
      <ListHead headers={["Name", "Type", "Status", "Last updated"]} />
      <ContractRow {...props} />
    </List>
  ),
  Filter,
  {
    title: "Contracts",
    base: "/flextime/contract?",
    init: {
      template: "",
      search: "",
      from: "",
      to: "",
      status: "",
      hasBeenDownloaded: "",
    },
    rule: "recruiter",
  }
);

import React from "react";
import CompanyForm from "../../forms/company";
import CreatePage from "../../components/CreatePage";

export const CompanyAdd = () => (
  <CreatePage namespace="company">
    {({ handleSubmit }) => (
      <CompanyForm
        action={handleSubmit}
        init={{
          name: "",
          url: "",
          street: "",
          city: "",
          province: "",
          postal: "",
          billingCycle: "",
          country: "",
        }}
      />
    )}
  </CreatePage>
);

export default CompanyAdd;

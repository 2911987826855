import React from "react";
import TimesheetComment from "./timesheetComment";

const TimesheetApproval = (props) => (
  <TimesheetComment
    {...props}
    commentKey="comment"
    statuses={{
      approved: "Approve",
      declined: "Decline",
    }}
  />
);

TimesheetApproval.propTypes = {};

export default TimesheetApproval;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const IconButton = styled.button`
    background: none;
    border: 0;
    text-decoration: underline;
    float: right;
    
    svg {
        height: 2rem;
        stroke: #1F1F1F;
        width: 2rem;
    }
`;

const RefreshButton = props => (
    <IconButton type="button" onClick={() => props.click()}>
        Refresh Rates
    </IconButton>
);

RefreshButton.propTypes = {
    click: PropTypes.func
};

export default RefreshButton;
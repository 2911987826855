import React from "react";
import { Full, Half } from "./Grid";
import ExpandedField from "./ExpandedField";
import { useField } from "formik";

const PermanentPlacementFeeField = () => {
  const [{ value }] = useField("feeType");
  const isFlat = value === "Flat";
  const Wrapper = isFlat ? Half : Full;

  return (
    <>
      <Wrapper>
        <ExpandedField label="Fee Type" component="select" name="feeType">
          <option value="">Select</option>
          <option value="Flat">Flat</option>
          <option value="Percent">Percent</option>
        </ExpandedField>
      </Wrapper>
      {value === "Percent" && (
        <>
          <Half>
            <ExpandedField
              label="Fee (%)"
              type="number"
              name="fee"
              helper="Required to calculate Permanent Placement Fee"
            />
          </Half>
          <Half>
            <ExpandedField
              disabled
              helper="This field is disabled because its value is auto-calculated. After you save the form, a value will appear (when applicable)."
              label="Permanent placement fee"
              name="clientRate"
              type="price"
            />
          </Half>
        </>
      )}
      {isFlat && (
        <Half>
          <ExpandedField label="Fee ($)" type="price" name="fee" />
        </Half>
      )}
    </>
  );
};

export default PermanentPlacementFeeField;

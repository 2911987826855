import React from "react";
import { useFormikContext } from "formik";
import InvoicingContacts from "../user/invoicingContacts";

const Invoicing = () => {
  const { values } = useFormikContext();

  return <InvoicingContacts id={values._id} values={values} />;
};

export default Invoicing;

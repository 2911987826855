import React from "react";
import ExpandedField from "../../components/ExpandedField";
import ExpandedCheckbox from "../../components/ExpandedCheckbox";
import ContractSharedForm from "./shared";
import { ProtectedElement } from "../../components/Protected";

const ContractEmployeeForm = (props) => (
  <ContractSharedForm
    {...props}
    contractorFields={
      <ExpandedField label="Date of Birth" name="birthday" type="date" />
    }
    checklist={
      <ProtectedElement rule="moderator">
        <ExpandedCheckbox
          label="Is the address complete?"
          name="isAddressComplete"
        />
        <ExpandedCheckbox
          label="Has DOB been provided?"
          name="hasDobBeenProvided"
        />
        <ExpandedCheckbox
          label="Has SIN been provided?"
          name="hasSinBeenProvided"
        />
        <ExpandedCheckbox
          label="Has TD1 Fed/Prov (signed) been returned?"
          name="hasTd1"
        />
      </ProtectedElement>
    }
  ></ContractSharedForm>
);

export default ContractEmployeeForm;

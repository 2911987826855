import React from "react";
import { map, get } from "lodash";
import { Query } from "react-apollo";
import Group from "../../components/Group";
import { useFormikContext } from "formik";
import { withUserDropdown } from "../user/po";
import ExpandedField from "../../components/ExpandedField";
import RateTypeField from "../../components/RateTypeField";
import { USER_DETAIL_CONTRACT } from "../../queries/user";
import { toDate } from "../utils";
import { UserInvoiceDropdown } from "../user/invoice";

const UserDropdown = withUserDropdown(({ options, ...rest }) => (
  <ExpandedField {...rest}>
    <option value="">Select</option>
    {map(options, (option) => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ))}
  </ExpandedField>
));

const UserDropdownSync = () => {
  const {
    setValues,
    values: { contractor, ...rest },
  } = useFormikContext();
  const [change, setChange] = React.useState(false);

  const vars = {
    id: contractor,
  };

  React.useEffect(() => {
    if (!contractor)
      setValues({
        ...rest,
        clientRate: "",
        candidateRate: "",
        role: "",
        fsContractNum: "",
        hst: "",
        startDate: "",
        endDate: "",
        po: "",
        manager: "",
        rateType: "",
      });
  }, [contractor]);

  React.useEffect(() => {
    if (contractor && contractor !== get(rest, "references.contractorId"))
      setChange(true);
  }, [contractor]);

  return change ? (
    <Query
      query={USER_DETAIL_CONTRACT}
      variables={vars}
      fetchPolicy="network-only"
    >
      {({ data = {} }) => {
        const { getUserContract } = data;

        React.useEffect(() => {
          if (getUserContract) {
            setValues({
              clientRate: getUserContract.billableRate,
              candidateRate: getUserContract.rate,
              role: getUserContract.role,
              fsContractNum: getUserContract.contract,
              hst: getUserContract.hst,
              startDate: toDate(getUserContract.startDate),
              endDate: toDate(getUserContract.endDate),
              po: getUserContract.po,
              manager: getUserContract.manager,
              rateType: getUserContract.rateType,
              contractor,
            });

            setChange(false);
          }
        }, [getUserContract, contractor]);

        return null;
      }}
    </Query>
  ) : null;
};

const ContractExtensionForm = () => (
  <>
    <Group>
      <UserDropdown all name="contractor" label="Contractor" />
      <UserInvoiceDropdown />
      <UserDropdownSync />
      <ExpandedField label="Position/Title" name="role" />
      <ExpandedField label="Flexstaf Account Manager" name="accountManager" />
      <ExpandedField label="FS contract #" name="fsContractNum" />
      <ExpandedField label="HST number" name="hst" />
      <ExpandedField label="Start date" name="startDate" type="date" />
      <ExpandedField label="End date" name="endDate" type="date" />
      <ExpandedField label="Client rate" name="clientRate" type="price" />
      <ExpandedField
        label="Candidate pay rate"
        name="candidateRate"
        type="price"
      />
      <RateTypeField required />
      <ExpandedField label="PO #" name="po" />
    </Group>
    <h3>Notes</h3>
    <ExpandedField label="" name="notes" component="textarea" rows={15} />
  </>
);

export default ContractExtensionForm;

import React from "react";
import withFilter from "../withFilter";
import List from "../../components/List";
import Filter from "../../forms/payout/filter";
import withQuery from "../../components/withQuery";
import ListHead from "../../components/ListHead";
import { toName } from "../timesheet";

const ContractRow = withQuery(
  ({ children, companyName, ...rest }) => (
    <li>
      <div>{toName(rest)}</div>
      <div>{companyName}</div>
      {children}
    </li>
  ),
  {
    namespace: "payout",
    queryKey: "getPayouts",
  }
);

export default withFilter(
  (props) => (
    <List>
      <ListHead headers={["Name", "Company"]} />
      <ContractRow {...props} />
    </List>
  ),
  Filter,
  {
    title: "Placements",
    base: "/flextime/payout?",
    init: { search: "" },
    rule: "admin",
  }
);

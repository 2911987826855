import React from "react";
import { Query, useMutation } from "react-apollo";
import Grid, { Half } from "../components/Grid";
import { Button } from "../components/Button";
import Alert from "../components/Alert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  TIMESHEET_GENERATEION,
  TIMESHEET_INVOICE_SUMMARY,
  INVOICE_GENERATION,
  COMMISSION_REPORT,
} from "../queries/timesheet";
import {
  USER_EXPORT_GENERATION,
  RECRUITER_EXPORT_GENERATION,
  USER_INACTIVE_EXPORT_GENERATION,
  CONTRACTOR_LIST_GENERATION,
} from "../queries/user";
import { PAYOUT_EXPORT_GENERATION } from "../queries/payout";
import {
  map,
  get,
  find,
  compact,
  size,
  sortBy,
  invoke,
  lowerCase,
} from "lodash";
import { saveAs } from "file-saver";
import * as yup from "yup";
import { UserCompanyList } from "../forms/user/company";
import { UserInvoiceDropdown } from "../forms/user/invoice";
import { ProtectedContext } from "../components/Protected";
import { USER_LIST_DROPDOWN } from "../queries/user";
import { MultiSelectField, Styled } from "../forms/user/po";

const PO = "PO";
const USERS = "USERS";
const QUICKBOOKS = "QUICKBOOKS";
const WORKERS = "WORKERS";
const WORKERS_INACTIVE = "WORKERS_INACTIVE";
const RECRUITERS = "RECRUITERS";
const PLACEMENTS = "PLACEMENTS";
const CONTRACTOR_LIST = "CONTRACTOR_LIST";

const cleanFileName = (str) =>
  String(lowerCase(str)).replace(/\s/g, "_").replace("generate_", "");

const generateFileName = (options = {}, args = {}) => {
  let name = cleanFileName(get(options, "label", "export"));

  ["from", "to"].forEach((item) => {
    if (get(args, item)) {
      name += `_${item}_${cleanFileName(get(args, item, "date"))}`;
    }
  });

  return `${name}.${get(options, "extension", "txt")}`;
};

const filterArgsBySelectionValues = (args = {}) => {
  if (args.selection === PO) {
    delete args.users;
  } else if (args.selection === USERS) {
    delete args.po;
    args.users = map(args.users, "value");
  } else if (!args.selection) {
    delete args.po;
    delete args.users;
  }

  delete args.selection;
  return args;
};

const makeFullName = (v) =>
  compact([get(v, "firstName"), get(v, "lastName")]).join(" ");

const ResetFieldWatcher = ({ values, setFieldValue }) => {
  React.useEffect(() => {
    setFieldValue("selection", "");
    setFieldValue("po", "");
    setFieldValue("users", "");
  }, [values.action, values.manager]);

  React.useEffect(() => {
    setFieldValue("manager", "");
  }, [values.company]);

  return null;
};

const SelectionDropdown = () => (
  <label>
    Choose a filter method:
    <Field name="selection" component="select">
      <option value="">None</option>
      <option value={USERS}>Select from a list of workers</option>
      <option value={PO}>Use a PO from manager profile</option>
    </Field>
    <ErrorMessage component="span" name="selection" />
  </label>
);

// eslint-disable-next-line
const withManager = (Component) => (props) =>
  // eslint-disable-next-line
  props.manager && props.show ? <Component {...props} /> : null;

const PoDropdown = withManager(({ data, manager }) => {
  const matchesActiveManager = (user) => get(user, "_id") === manager;

  const options = get(find(data, matchesActiveManager), "pos", []);
  const length = size(options);

  return (
    <label>
      POs
      <Field name="po" component="select" disabled={!length}>
        <option value="">{length ? "Select" : "None available"}</option>
        {map(options, (item) => (
          <option key={item.value} value={item.value}>
            {item.value}
          </option>
        ))}
      </Field>
      <ErrorMessage component="span" name="po" />
    </label>
  );
});

const UserListByManager = withManager(({ manager }) => (
  <Query
    query={USER_LIST_DROPDOWN}
    fetchPolicy="network-only"
    variables={{ manager }}
  >
    {({ data }) => (
      <Styled>
        <label>
          Workers
          <MultiSelectField
            name="users"
            options={map(get(data, "getUsersForSelect", []), (item) => ({
              label: makeFullName(item),
              value: item._id,
            }))}
          />
          <ErrorMessage component="span" name="users" />
        </label>
      </Styled>
    )}
  </Query>
));

const SelectionFields = (props) => {
  // eslint-disable-next-line
  const { action, company, manager, selection } = props;

  const isQuickbooks = action === QUICKBOOKS;
  const isPo = selection === PO;
  const isUsers = selection === USERS;

  return (
    <>
      <UserCompanyList required />
      <UserInvoiceDropdown required company={company}>
        {(data) =>
          manager && isQuickbooks
            ? compact([
                <SelectionDropdown key="selection-dropdown" />,
                <PoDropdown
                  data={get(data, "getUsersForSelect", [])}
                  key="po-dropdown"
                  manager={manager}
                  show={isPo}
                />,
                <UserListByManager
                  key="users-dropdown"
                  manager={manager}
                  show={isUsers}
                />,
              ])
            : null
        }
      </UserInvoiceDropdown>
    </>
  );
};

const Tools = () => {
  const auth = React.useContext(ProtectedContext);
  const [error, setError] = React.useState(false);

  const options = {
    TIMESHEET: {
      label: "Generate timesheet PDF",
      mutate: useMutation(TIMESHEET_GENERATEION),
      response: "generateTimesheetBundle",
      extension: "pdf",
    },
    INVOICES: {
      label: "Generate invoice summary CSV",
      mutate: useMutation(TIMESHEET_INVOICE_SUMMARY),
      response: "generateTimesheetInvoiceSummary",
      extension: "csv",
    },
    [QUICKBOOKS]: {
      label: "Generate invoice in Quickbooks",
      mutate: useMutation(INVOICE_GENERATION),
      response: "generateQuickbooksInvoice",
      extension: "link",
    },
    [WORKERS_INACTIVE]: {
      label: "Generate worker summary CSV (Inactive)",
      mutate: useMutation(USER_INACTIVE_EXPORT_GENERATION),
      response: "generateUserInactiveExportReport",
      extension: "csv",
    },
    [WORKERS]: {
      label: "Generate worker summary CSV (Active)",
      mutate: useMutation(USER_EXPORT_GENERATION),
      response: "generateUserExportReport",
      extension: "csv",
    },
    ...(auth.type === "administrator"
      ? {
          [CONTRACTOR_LIST]: {
            label: "Generate contractor list CSV",
            mutate: useMutation(CONTRACTOR_LIST_GENERATION),
            response: "genContractorList",
            extension: "csv",
          },
          [PLACEMENTS]: {
            label: "Generate permanent placement CSV",
            mutate: useMutation(PAYOUT_EXPORT_GENERATION),
            response: "generatePlacementsReport",
            extension: "csv",
          },
          [RECRUITERS]: {
            label: "Generate recruiter/subvendor commissions summary CSV",
            mutate: useMutation(RECRUITER_EXPORT_GENERATION),
            response: "generateRecruiterCommissionsReport",
            extension: "csv",
          },
          COMMISSIONS: {
            label: "Generate commission report",
            mutate: useMutation(COMMISSION_REPORT),
            response: "generateCommissionReport",
            extension: "xlsx",
          },
        }
      : {}),
  };

  const handleSubmit = ({ action, ...args }) => {
    setError(false);

    const doesNotRequireVariables = [
      WORKERS,
      RECRUITERS,
      PLACEMENTS,
      WORKERS_INACTIVE,
      CONTRACTOR_LIST,
    ].includes(action);

    return Promise.resolve(
      invoke(
        options,
        `${action}.mutate.0`,
        doesNotRequireVariables
          ? {
              variables: {},
            }
          : {
              variables: {
                ...filterArgsBySelectionValues(args),
                companyId: args.company,
              },
            }
      )
    )
      .then((res) => {
        // eslint-disable-next-line
        const opts = options[action];
        const buf = get(res, `data.${opts.response}.data`);

        if (opts.extension === "link") {
          // eslint-disable-next-line
          window.open(buf);
        } else {
          const buf = get(res, `data.${opts.response}.data`);
          const parsed = JSON.parse(buf);
          const a = Buffer.from(parsed.data);
          const uni = new Uint8Array(a);

          saveAs(
            new Blob([uni]),
            generateFileName(opts, doesNotRequireVariables ? {} : args)
          );
        }
      })
      .catch((e) => {
        if (e.message && e.message.includes("No results")) {
          setError("empty");
        } else {
          setError("server");
        }
      });
  };

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          from: "",
          to: "",
          action: "TIMESHEET",
          company: "",
          manager: "",
          selection: "",
        }}
        validationSchema={yup.object().shape({
          from: yup.date(),
          to: yup.date(),
          action: yup.string().required(),
        })}
      >
        {({ values, isSubmitting, ...etc }) => (
          <Form>
            <ResetFieldWatcher
              values={values}
              setFieldValue={etc.setFieldValue}
            />

            <h1>Automation Tools</h1>
            <label>
              Action *
              <Field required name="action" component="select">
                {sortBy(
                  Object.entries(options),
                  ([, values]) => values.label
                ).map(([key, { label }]) => (
                  <option value={key} key={key}>
                    {label}
                  </option>
                ))}
              </Field>
              <ErrorMessage component="span" name="action" />
            </label>
            {![
              WORKERS,
              RECRUITERS,
              PLACEMENTS,
              WORKERS_INACTIVE,
              CONTRACTOR_LIST,
            ].includes(values.action) && (
              <Grid>
                <Half>
                  <label>
                    From *
                    <Field name="from" type="date" required />
                    <ErrorMessage component="span" name="from" />
                  </label>
                </Half>
                <Half>
                  <label>
                    To *
                    <Field name="to" type="date" required />
                    <ErrorMessage component="span" name="to" />
                  </label>
                </Half>
              </Grid>
            )}
            {["QUICKBOOKS", "TIMESHEET"].includes(values.action) && (
              <SelectionFields {...values} />
            )}
            <Button disabled={isSubmitting} secondary type="submit">
              {isSubmitting ? "Generating..." : "Send"}
            </Button>
          </Form>
        )}
      </Formik>
      {error === "server" && (
        <Alert timeout={false}>
          The request encountered an error or it could not load any data for the
          given filter. If you are performing a QuickBooks operation, please try
          re-freshing the authentication by clicking here:{" "}
          <a
            // eslint-disable-next-line
            target="_blank"
            href={process.env.GATSBY_QUICKBOOKS_AUTHORIZE}
            style={{
              color: "#FFF",
              textDecoration: "underline",
              padding: "0 .25rem",
            }}
          >
            Authorize
          </a>
        </Alert>
      )}
      {error === "empty" && (
        <Alert>Nothing to generate for the given query.</Alert>
      )}
    </>
  );
};

export default Tools;

import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { Button } from './Print';
import withMedia from './withMedia';

const ButtonVariant = styled(Button)`
    background: transparent;
    color: #959595;
    cursor: pointer;
    display: block;
    margin-top: 2rem;
    transition: filter 500ms;

    &:hover {
        filter: brightness(10%);
    }

    svg {
        fill: none;
        height: 1.5rem;
        stroke: #959595;
        width: 1.5rem;
    }

    ${withMedia('tablet', css`

        padding: 1rem;
        position: relative;
        right: auto;
        top: auto;
    `)}
`;

const Trash = ({ func }) => (
    <ButtonVariant onClick={func} tabindex="0" role="button" aria-pressed="false">
        <Icon name="Trash" />
        <span>Trash</span>
    </ButtonVariant>
);

Trash.propTypes = {
    func: PropTypes.func
};

export default Trash;
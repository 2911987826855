import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import Submit from "../../components/Submit";
import Clear from "../../components/Clear";

const CompanyFilter = ({ action, init }) => (
  <Formik
    initialValues={init}
    enableReinitialize
    onSubmit={(variables) => action(variables)}
    render={() => (
      <Form>
        <label style={{ marginTop: "1rem" }}>
          Name
          <Field name="search" type="text" />
        </label>
        <Submit text="Search" />
        <Clear />
      </Form>
    )}
  />
);

CompanyFilter.propTypes = {
  init: PropTypes.object,
  action: PropTypes.func,
  clear: PropTypes.func,
};

export default CompanyFilter;

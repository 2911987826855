import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import Submit from "../../components/Submit";
import { Query } from "react-apollo";
import { USER_LIST_DROPDOWN } from "../..//queries/user";
import { ProtectedElement } from "../../components/Protected";
import { InlineForm } from "../user/filter";
import Clear from "../../components/Clear";

const TimesheetApproval = ({ action, init }) => (
  <Formik
    initialValues={init}
    enableReinitialize
    onSubmit={(variables) => action(variables)}
    render={() => (
      <Form>
        <InlineForm>
          <label>
            Starting from Date
            <Field name="date" type="date" />
          </label>

          <label>
            TimeSheet Status
            <Field component="select" name="status">
              <option value="">All</option>
              <option value="draft">Draft</option>
              <option value="prereview">Pre-Review</option>
              <option value="review">Review</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="declined">Declined</option>
            </Field>
          </label>

          <ProtectedElement rule="managerGroup">
            <label>
              Belongs To User
              <Field component="select" name="belongsTo">
                <option value="">All</option>

                <Query
                  query={USER_LIST_DROPDOWN}
                  variables={{ contractorsOnly: true }}
                >
                  {({ data, loading }) =>
                    !loading ? (
                      data.getUsersForSelect.map((obj, i) => (
                        <option value={obj._id.toString()} key={i}>
                          {obj.firstName} {obj.lastName}
                        </option>
                      ))
                    ) : (
                      <option value="">Loading</option>
                    )
                  }
                </Query>
              </Field>
            </label>
          </ProtectedElement>
        </InlineForm>

        <Submit text="Search" />
        <Clear />
      </Form>
    )}
  />
);

TimesheetApproval.propTypes = {
  init: PropTypes.object,
  action: PropTypes.func,
  clear: PropTypes.func,
};

export default TimesheetApproval;

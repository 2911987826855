import React from "react";
import { Mutation } from "react-apollo";
import Alert from "../../components/Alert";
import { USER_CREATE, USER_LIST, USER_LIST_DROPDOWN } from "../../queries/user";
import UserTypes from "../../../static/users.json";
import UserForm from "../../forms/user";
import { ProtectedContext } from "../../components/Protected";
import RoleTypeOptions from "../../forms/user/roleTypeOptions";
import CreatePage from "../../components/CreatePage";

export const UserAdd = () => {
  const roles = { ...UserTypes };
  const auth = React.useContext(ProtectedContext);
  const [state, setState] = React.useState("subcontractor");

  const onSelect = ({ target }) => setState(target.value);

  if (auth?.type === "moderator") {
    delete roles.administrator;
    delete roles.recruiter;
  }

  return (
    <CreatePage namespace="user">
      {({ handleSubmit }) => (
        <>
          <select name="role" onChange={onSelect} required value={state}>
            <RoleTypeOptions />
          </select>
          <UserForm query={handleSubmit} role={state} />
        </>
      )}
    </CreatePage>
  );
};

export default UserAdd;

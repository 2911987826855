import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const ExpandedCheckbox = ({ label, name, ...props }) => (
  <label>
    <Field name={name} type="checkbox" {...props} />
    {label}
    <ErrorMessage component="span" name={name} style={{ display: "block" }} />
  </label>
);

ExpandedCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ExpandedCheckbox;

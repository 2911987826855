/* eslint-disable react/prop-types */
import React from "react";
import { Formik, Form } from "formik";
import { size } from "lodash";
import { Button } from "../../components/Button";
import Contractor from "./contractor";
import Employee from "./employee";
import Extension from "./extension";
import Permanent from "./permanent";
import DetailPageErrorsConsumer from "../../components/DetailPageErrorsConsumer";
import { toDate, mapExpiryDate } from "../utils";
import Payroll from "./payroll";

export const templateMap = {
  "Subcontractor Placement": Contractor,
  "Temporary Employee Placement": Employee,
  "Contract Extension/Revision": Extension,
  "Permanent Placement": Permanent,
  "Client Payroll Placement": Payroll,
};

const ContractForm = ({ status, template, onSubmit, ...props }) => (
  <Formik
    id="contract-checklist"
    enableReinitialize
    onSubmit={onSubmit}
    validateOnBlur={false}
    initialValues={{
      company: "",
      manager: "",
      contractor: "",
      nm: 0,
      gm: 0,
      fee: 0,
      hasSocialSecurityBeenReceived: false,
      portalUserType: "Flextime Timesheet",

      ...props,
      invoiceDate: toDate(props.invoiceDate),
      startDate: toDate(props.startDate),
      endDate: toDate(props.endDate),
      birthday: toDate(props.birthday),
      firstPaymentDate: toDate(props.firstPaymentDate),
      invoicingContacts: size(props.invoicingContacts)
        ? props.invoicingContacts
        : [
            {
              name: "",
              email: "",
              phone: "",
              title: "",
            },
          ],
      payouts: size(props.payouts)
        ? props.payouts
        : [
            {
              recruiter: "",
              rate: "",
              month: "",
              year: "",
            },
          ],
      guarantees: mapExpiryDate(
        size(props.guarantees)
          ? props.guarantees
          : [
              {
                guarantee: "",
                expiryDate: "",
              },
            ]
      ),
    }}
  >
    {({ setFieldValue, errors }) => {
      // eslint-disable-next-line
      const El = templateMap[template] || React.createElement("div");

      React.useEffect(() => {
        setFieldValue("status", props.status);
      }, [errors]);

      const handleClick = (newStatusValue) => (e) => {
        e.stopPropagation();

        if (newStatusValue !== status) {
          if (confirm("Are you sure?")) {
            setFieldValue("status", newStatusValue);
            try {
              document.getElementById("submit").click();
            } catch (e) {
              alert("Form failed to submit");
            }
          } else {
            e.preventDefault();
          }
        }
      };

      const makeButton = ({
        label = "Continue",
        currentStatus = "Any",
        // keeps it the same
        newStatusValue = status,
      }) => {
        const always = currentStatus === "Any";
        const buttonProps = always
          ? {
              id: "submit",
              primary: true,
              type: "submit",
            }
          : {
              secondary: true,
              type: "button",
              style: {
                marginRight: ".5rem",
              },
            };

        return (status || "Draft") === currentStatus || always ? (
          <Button {...buttonProps} onClick={handleClick(newStatusValue)}>
            {label}
          </Button>
        ) : null;
      };

      return (
        <Form>
          <El status={status} template={template} />
          <DetailPageErrorsConsumer />
          <div style={{ marginTop: "1rem" }}>
            {makeButton({
              currentStatus: "Any",
              label: "Save",
            })}
            {makeButton({
              currentStatus: "Draft",
              newStatusValue: "Review",
              label: "Submit for review",
            })}
            {makeButton({
              currentStatus: "Submitted",
              newStatusValue: "Accept",
              label: "Submit for review",
            })}
            {makeButton({
              currentStatus: "Pending",
              newStatusValue: "Merge",
              label: "Complete",
            })}
          </div>
        </Form>
      );
    }}
  </Formik>
);

export default ContractForm;

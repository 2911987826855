import React from "react";
import { Query, useQuery } from "react-apollo";
import App from "./_app";
import { PROFILE } from "../queries/account.js";
import { Provider } from "../components/Protected";
import Sidebar from "../components/SideBar";
import { ContainerLarge } from "../components/Container";
import Wrap from "../components/Wrap";
import FlexTime from "../flextime";
import { Redirect } from "@reach/router";
import Helmet from "react-helmet";

const Index = () => {
  const { data } = useQuery(PROFILE, {
    pollInterval: 300000,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return data && data.profile ? (
    <Provider value={data.profile}>
      <Helmet>
        <title>FlexTime Contractor Portal</title>
      </Helmet>

      <App>
        <ContainerLarge>
          <Sidebar
            name={data.profile.firstName}
            contract={data.profile.contract}
            role={data.profile.type}
          />
        </ContainerLarge>

        <Wrap muted style={{ paddingTop: "1px" }}>
          <ContainerLarge>
            <FlexTime />
          </ContainerLarge>
        </Wrap>
      </App>
    </Provider>
  ) : (
    "Connecting to Flextime..."
  );
};

export default Index;

import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Formik, Form, Field } from "formik";
import Submit from "../../components/Submit";
import withMedia from "../../components/withMedia";
import Clear from "../../components/Clear";
import RoleTypeOptions from "./roleTypeOptions";

export const InlineForm = styled.div`
  display: flex;
  max-width: 100%;

  label {
    margin: 1rem 0.5% 1rem 0;
    width: 31%;
  }

  ${withMedia(
    "phone",
    css`
      display: block;
      label {
        width: 100%;
      }
    `
  )}
`;

const TimesheetApproval = ({ action, init }) => (
  <Formik
    initialValues={init}
    enableReinitialize
    onSubmit={(variables) => action(variables)}
    render={() => (
      <Form>
        <InlineForm>
          <label>
            Search by name or email
            <Field name="search" type="text" />
          </label>

          <label>
            User Type
            <Field component="select" name="type">
              <option value="">All</option>
              <RoleTypeOptions />
            </Field>
          </label>
          <label>
            Status
            <Field component="select" name="active">
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </Field>
          </label>
        </InlineForm>
        <Submit text="Search" />
        <Clear />
      </Form>
    )}
  />
);

TimesheetApproval.propTypes = {
  init: PropTypes.object,
  action: PropTypes.func,
};

export default TimesheetApproval;

import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { useQueryNamespace } from "./DetailPageMutation";
import useGraphqlErrors from "./useGraphqlErrors";
import AlertSuccess from "./AlertSuccess";
import GraphqlErrors from "./GraphqlErrors";
import PageWrapper from "./PageWrapper";
import DetailPageErrors from "./DetailPageErrors";
import { isFunction } from "lodash";

const CreatePage = ({ children, namespace, onDone }) => {
  const qn = useQueryNamespace(namespace);
  const { errors, errorObject, clearErrors, handleErrors } = useGraphqlErrors();

  return (
    <DetailPageErrors.Provider value={errorObject}>
      <Mutation
        mutation={qn("create")}
        refetchQueries={[{ query: qn("list") }]}
      >
        {(query, { data }) => (
          <PageWrapper title="Add new">
            {children({
              handleSubmit: (variables) =>
                query({ variables })
                  .then((res) => {
                    if (isFunction(onDone)) onDone(res);
                    return clearErrors(res);
                  })
                  .catch((e) => {
                    handleErrors(e);
                    throw e;
                  }),
            })}
            <AlertSuccess in={data} />
            <GraphqlErrors errors={errors} />
          </PageWrapper>
        )}
      </Mutation>
    </DetailPageErrors.Provider>
  );
};

CreatePage.propTypes = {
  children: PropTypes.func.isRequired,
  namespace: PropTypes.func.isRequired,
  onDone: PropTypes.func,
};

export default CreatePage;

import React from "react";
import moment from "moment";

const OptionsYear = () => {
  const Start = new Date();
  const inFiveYears = moment().add(5, "year").toDate();
  const length = moment(inFiveYears).diff(Start, "years");
  const currentYear = Start.getFullYear();

  const incrementYear = (_, idx) => currentYear + idx;

  return (
    <>
      <option>Select</option>
      {Array.from({
        length,
      })
        .map(incrementYear)
        .map((month) => (
          <option value={month} key={month}>
            {month}
          </option>
        ))}
    </>
  );
};

export default OptionsYear;

import React from "react";
import PropTypes from "prop-types";
import { useParams } from "@reach/router";
import { Query } from "react-apollo";
import { isFunction, isObject } from "lodash";
import TrashMutationContainer from "./TrashMutationContainer";
import LoadingView from "./Loading";
import useGraphqlErrors from "./useGraphqlErrors";
import AlertSuccess from "./AlertSuccess";
import GraphqlErrors from "./GraphqlErrors";
import DetailPageMutation, { useQueryNamespace } from "./DetailPageMutation";
import DetailPageErrors from "./DetailPageErrors";
import PageWrapper from "./PageWrapper";

const DetailPage = ({
  children,
  renderTitle,
  namespace,
  marshal,
  trashAuthScope,
}) => {
  const { id } = useParams();
  const qn = useQueryNamespace(namespace);
  const { errorObject, errors, clearErrors, handleErrors } = useGraphqlErrors();
  const path = `/flextime/${namespace}`;

  const fixCoersion = (xs) =>
    isObject(xs)
      ? Object.entries(xs).reduce((acc, [key, value]) => {
          acc[key] = value || null;
          return acc;
        }, {})
      : xs;

  return (
    <DetailPageErrors.Provider value={errorObject}>
      <Query query={qn("detail")} variables={{ id }}>
        {({ loading, data }) => {
          return !loading ? (
            <PageWrapper
              title={isFunction(renderTitle) ? renderTitle(data) : undefined}
            >
              <DetailPageMutation id={id} namespace={namespace}>
                {(update, updateState) => (
                  <>
                    {children({
                      ...updateState,

                      handleSubmit: (payload) => {
                        const variables = fixCoersion(
                          isFunction(marshal) ? marshal(payload) : payload
                        );

                        if (id) variables.id = id;
                        return update({
                          variables,
                        })
                          .then(clearErrors)
                          .catch(handleErrors);
                      },

                      data,
                      errorObject,
                    })}
                    <AlertSuccess
                      in={updateState.data && !updateState.errors}
                    />
                  </>
                )}
              </DetailPageMutation>
              <GraphqlErrors errors={errors} />
              <TrashMutationContainer
                id={id}
                mutation={qn("delete")}
                query={qn("list")}
                path={path}
                rule={trashAuthScope}
              />
            </PageWrapper>
          ) : (
            <LoadingView show={loading} />
          );
        }}
      </Query>
    </DetailPageErrors.Provider>
  );
};

DetailPage.defaultProps = {
  trashAuthScope: "admin",
};

DetailPage.propTypes = {
  children: PropTypes.func.isRequired,
  renderTitle: PropTypes.func,
  namespace: PropTypes.string.isRequired,
  marshal: PropTypes.func,
  trashAuthScope: PropTypes.string,
};

export default DetailPage;

import React from "react";
import TimesheetComment from "./timesheetComment";

const TimesheetReview = (props) => (
  <TimesheetComment
    {...props}
    commentKey="commentOnReview"
    statuses={{
      pending: "Send to manager (Pending)",
      declined: "Return to worker for change (Declined)",
    }}
  />
);

TimesheetReview.propTypes = {};

export default TimesheetReview;

import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import Group from "../../components/Group";
import withFieldRepeater from "../../components/withFieldRepeater";
import ExpandedField from "../../components/ExpandedField";
import { getFirstNameByUserId } from "../utils";
import OptionsMonth from "../../components/OptionsMonth";
import OptionsYear from "../../components/OptionsYear";
import withUserDropdown from "../../components/withUserDropdown";
import { useFormikContext } from "formik";

const Commission = ({ idx, includeDates, recruiters }) => (
  <Group>
    <ExpandedField
      label="Recruiter/Subvendor"
      name={`payouts[${idx}].recruiter`}
    >
      <option value="">Select</option>
      {map(recruiters, (obj, i) => (
        <option key={i} value={obj._id}>
          {obj.firstName} {obj.lastName}
        </option>
      ))}
    </ExpandedField>
    <ExpandedField label="Rate" name={`payouts[${idx}].rate`} type="price" />
    {includeDates && (
      <>
        <ExpandedField label="Month" name={`payouts[${idx}].month`}>
          <OptionsMonth />
        </ExpandedField>
        <ExpandedField label="Year" name={`payouts[${idx}].year`}>
          <OptionsYear />
        </ExpandedField>
      </>
    )}
  </Group>
);

Commission.propTypes = {
  idx: PropTypes.number.isRequired,
  includeDates: PropTypes.bool,
  recruiters: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    })
  ),
};

const El = withFieldRepeater(
  Commission,
  {
    title: "Commissions/Payouts",
    key: "payouts",
  },
  {
    recruiter: "",
    rate: "",
    month: "",
    year: "",
  }
);

export default withUserDropdown(
  ({ users, ...props }) => (
    <El
      {...useFormikContext()}
      {...props}
      recruiters={users}
      sortingFn="name"
      mappingFn={(xs) => ({
        name: getFirstNameByUserId(users, xs.recruiter),
        ...xs,
      })}
    />
  ),
  {
    recruitersOnly: true,
  }
);

import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";

const includesProp = (obj, key) => {
  if (!(key in obj) || obj[key] === null || obj[key] === "") {
    return false;
  }

  return true;
};

const groupByWeek = (logs) =>
  logs.reduce(
    (acc, current) => {
      if (moment.utc(current.date).day() === 6) {
        acc.push([]);
      }

      acc[acc.length - 1].push(current);
      return acc;
    },
    [[]]
  );

class Invoice extends Component {
  componentDidMount = () => {
    if (typeof window === undefined) return;
    let host = window.location.protocol + "//" + window.location.host;

    Font.register(`${host}/Muli-Regular.ttf`, { family: "Muli-Regular" });
    Font.register(`${host}/Muli-Bold.ttf`, { family: "Muli-Bold" });
    Font.register(`${host}/Cedarville-Cursive.ttf`, {
      family: "Cedarville-Cursive",
    });
  };

  render = () => (
    <Document>
      <Page style={styles.page} wrap>
        <View style={styles.grid}>
          <View style={styles.column}>
            {this.props.invoice ? (
              <>
                <View>
                  <Text style={styles.columnTitle}>DETAILS</Text>
                  {includesProp(
                    this.props.sheet.belongsTo,
                    "paymentMadeTo"
                  ) && (
                    <Text style={styles.block}>
                      <Text style={styles.emphasis}>Business Name: </Text>
                      <Text>{this.props.sheet.belongsTo.paymentMadeTo}</Text>
                    </Text>
                  )}

                  <Text style={styles.block}>
                    <Text style={styles.emphasis}>Name: </Text>
                    <Text>
                      {this.props.sheet.belongsTo.firstName}{" "}
                      {this.props.sheet.belongsTo.lastName}
                    </Text>
                  </Text>

                  <Text style={styles.block}>
                    <Text style={styles.emphasis}>Address: </Text>
                    <Text>
                      {this.props.sheet.belongsTo.street}{" "}
                      {this.props.sheet.belongsTo.city}{" "}
                      {this.props.sheet.belongsTo.province}{" "}
                      {this.props.sheet.belongsTo.country}{" "}
                      {this.props.sheet.belongsTo.postal}
                    </Text>
                  </Text>

                  <Text style={styles.block}>
                    <Text style={styles.emphasis}>Email: </Text>
                    <Text>{this.props.sheet.belongsTo.email}</Text>
                  </Text>

                  {includesProp(this.props.sheet.belongsTo, "hst") && (
                    <Text style={styles.block}>
                      <Text style={styles.emphasis}>HST Registration: </Text>
                      <Text>{this.props.sheet.belongsTo.hst}</Text>
                    </Text>
                  )}

                  {includesProp(this.props.sheet.belongsTo, "phone") && (
                    <Text style={styles.block}>
                      <Text style={styles.emphasis}>Telephone: </Text>
                      <Text>{this.props.sheet.belongsTo.phone}</Text>
                    </Text>
                  )}
                </View>

                <View style={{ marginTop: "10pt" }}>
                  <Text style={styles.block}>
                    <Text style={styles.emphasis}>Bill to: </Text>
                    <Text>
                      FlexStaf IT 105 Consumers Drive, Suite #N219, Whitby, ON
                      L1N 1C4
                    </Text>
                  </Text>
                  <Text style={styles.block}>
                    <Text style={styles.emphasis}>Attention: </Text>
                    <Text>
                      Accounts Payable; Email: accounting@flexstaf-it.ca
                    </Text>
                  </Text>
                </View>
              </>
            ) : (
              <View>
                <Text style={styles.columnTitle}>DETAILS</Text>
                <Text style={styles.block}>
                  <Text style={styles.emphasis}>Name: </Text>
                  <Text>
                    {this.props.sheet.belongsTo.firstName}{" "}
                    {this.props.sheet.belongsTo.lastName}
                  </Text>
                </Text>
              </View>
            )}
          </View>

          <View style={styles.column}>
            <View>
              {this.props.invoice ? (
                <Text style={styles.columnTitle}>INVOICE</Text>
              ) : (
                <Text style={styles.columnTitle}>TIMESHEET</Text>
              )}

              <Text style={styles.block}>
                <Text style={styles.emphasis}>Document No: </Text>
                <Text>#{this.props.num}</Text>
              </Text>
            </View>

            <Text style={styles.block}>
              <Text style={styles.emphasis}>From Date: </Text>
              <Text>
                {moment.utc(this.props.sheet.from).format("YYYY-MM-DD")}
              </Text>
            </Text>

            <Text style={styles.block}>
              <Text style={styles.emphasis}>To Date: </Text>
              <Text>
                {moment.utc(this.props.sheet.to).format("YYYY-MM-DD")}
              </Text>
            </Text>

            <Text style={styles.block}>
              <Text style={styles.emphasis}>Contract: </Text>
              <Text>#{this.props.sheet.belongsTo.contract}</Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Manager: </Text>
              <Text>
                {this.props.sheet.managedBy.firstName}{" "}
                {this.props.sheet.managedBy.lastName}
              </Text>
            </Text>
          </View>
        </View>
        {groupByWeek(this.props.rows).map((week, i) => {
          let hours = 0;
          let subtotal = 0;

          return week.length ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={{ ...styles.tableCell, width: 75 }}>
                  <Text>Date</Text>
                </View>
                <View style={{ ...styles.tableCell, width: 50 }}>
                  <Text>Duration (hrs)</Text>
                </View>
                <View style={{ ...styles.tableCell, flex: 1 }}>
                  <Text>Description</Text>
                </View>

                {this.props.invoice && (
                  <>
                    <View style={{ ...styles.tableCell, width: 50 }}>
                      <Text>Rate ($)</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: 75 }}>
                      <Text>Total ($)</Text>
                    </View>
                  </>
                )}

                <View style={{ ...styles.tableCell, width: 75 }}>
                  <Text>Status</Text>
                </View>
              </View>

              {week.map((obj, i) => {
                hours += obj.hours;
                subtotal += obj.subtotal;

                return (
                  <View style={styles.tableRow} key={i}>
                    <View style={{ ...styles.tableCell, width: 75 }}>
                      <Text>{moment.utc(obj.date).format("MM-DD-YYYY")}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, width: 50 }}>
                      <Text>{obj.hours}</Text>
                    </View>
                    <View style={{ ...styles.tableCell, flex: 1 }}>
                      <Text>{obj.description}</Text>
                    </View>

                    {this.props.invoice && (
                      <>
                        <View style={{ ...styles.tableCell, width: 50 }}>
                          <Text>{obj.rate}</Text>
                        </View>
                        <View style={{ ...styles.tableCell, width: 75 }}>
                          <Text>{Number(obj.subtotal).toFixed(2)}</Text>
                        </View>
                      </>
                    )}

                    <View style={{ ...styles.tableCell, width: 75 }}>
                      <Text>{this.props.sheet.status}</Text>
                    </View>
                  </View>
                );
              })}

              <View style={styles.smallText}>
                <Text>Total number of hours: {Number(hours).toFixed(2)}</Text>
                {this.props.invoice && (
                  <Text>Weekly rate: ${Number(subtotal).toFixed(2)}</Text>
                )}
              </View>
            </View>
          ) : null;
        })}

        {this.props.invoice ? (
          <View style={styles.total}>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Total Hours: </Text>
              <Text>{this.props.sheet.total}</Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Subtotal: </Text>
              <Text>${Number(this.props.sheet.subtotal).toFixed(2)}</Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Tax: </Text>
              <Text>{this.props.sheet.taxOnApproval || 0}%</Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Total: </Text>
              {/** EXTRACT THIS INTO MAIN VIEW */}
              <Text>
                $
                {this.props.sheet.taxOnApproval
                  ? Number(
                      (this.props.sheet.taxOnApproval / 100) *
                        Number(this.props.sheet.subtotal) +
                        Number(this.props.sheet.subtotal)
                    ).toFixed(2)
                  : Number(this.props.sheet.subtotal).toFixed(2)}
              </Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Signature: </Text>
              <Text style={styles.cursive}>
                {this.props.sheet.belongsTo.firstName}{" "}
                {this.props.sheet.belongsTo.lastName}
              </Text>
            </Text>
          </View>
        ) : (
          <View style={styles.total}>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Total Hours: </Text>
              <Text>{Number(this.props.sheet.total).toFixed(2)}</Text>
            </Text>
            <Text style={styles.block}>
              <Text style={styles.emphasis}>Signature: </Text>
              <Text style={styles.cursive}>
                {this.props.sheet.managedBy.firstName}{" "}
                {this.props.sheet.managedBy.lastName}
              </Text>
            </Text>
          </View>
        )}
      </Page>
    </Document>
  );
}

Invoice.propTypes = {
  hours: PropTypes.number,
  num: PropTypes.number,
  sheet: PropTypes.object,
  rows: PropTypes.array.isRequired,
  total: PropTypes.number,
};

const styles = StyleSheet.create({
  page: {
    padding: "15pt 10pt",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
  },
  column: {
    display: "block",
    flexBasis: "40%",
    marginBottom: "25p6",
    width: "40%",
  },
  columnTitle: {
    fontSize: "18pt",
    marginBottom: "10pt",
    fontFamily: "Muli-Bold",
  },
  emphasis: {
    fontFamily: "Muli-Bold",
    textTransform: "uppercase",
  },
  cursive: {
    display: "block",
    fontFamily: "Cedarville-Cursive",
  },
  table: {
    display: "flex",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  smallText: {
    fontSize: "10pt",
    marginBottom: "10pt",
    marginTop: "2pt",
  },
  tableCell: {
    fontSize: "8pt",
    border: "1pt solid #000",
    margin: 0,
    padding: "5pt",
  },
  block: {
    display: "block",
    fontSize: "8pt",
    marginBottom: "1pt",
    width: "100%",
  },
  total: {
    marginLeft: "60%",
    marginTop: "15pt",
  },
});

export default Invoice;

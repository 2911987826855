import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Link } from "gatsby";
import { get, size, map } from "lodash";
import { ViewMore } from "./List";
import Icon from "./Icon";
import LoadingView from "./Loading";
import { useQueryNamespace } from "./DetailPageMutation";
import { Previous, Next } from "./Pagination";

export default (Component, { namespace, queryKey }) => {
  const qn = useQueryNamespace(namespace);

  const QueryList = ({ getVariables, isFirst, next, prev }) => (
    <Query
      query={qn("list")}
      polling={2000}
      fetchPolicy="cache-and-network"
      variables={getVariables()}
    >
      {({ data, loading }) => {
        const d = get(data, queryKey);
        return !loading && data ? (
          <>
            {size(d) ? (
              map(d, (obj, i) => (
                <Component key={i} {...obj}>
                  <ViewMore>
                    <Link to={`/flextime/${namespace}/${obj._id}`}>
                      <Icon name="Edit" />
                    </Link>
                  </ViewMore>
                </Component>
              ))
            ) : (
              <li>
                <div>No results</div>
              </li>
            )}
            <li>
              <Previous disabled={isFirst()} onClick={prev} />
              <Next disabled={size(d) !== 25} onClick={next} />
            </li>
          </>
        ) : (
          <LoadingView show={loading} />
        );
      }}
    </Query>
  );

  QueryList.propTypes = {
    renderPagination: PropTypes.func,
    getVariables: PropTypes.func,
    prev: PropTypes.func,
    next: PropTypes.func,
    isFirst: PropTypes.func,
  };

  return QueryList;
};

import React from "react";
import { uniq, map, get, compact, size } from "lodash";

export default () => {
  const [errors, setErrors] = React.useState([]);
  const [errorObject, setErrorObject] = React.useState({});

  return {
    errors,
    errorObject,

    clearErrors: () => {
      setErrors([]);
      setErrorObject({});
    },

    handleErrors: (e) => {
      const errs = compact(
        map(map(get(e, "networkError.result.errors"), "message"))
      );

      let newErrorState;

      if (!size(errs)) {
        newErrorState = [e.message];
      } else {
        newErrorState = errs;
      }

      const newErrorStateObject = newErrorState.reduce((acc, curr) => {
        const attempt = (fn) => {
          try {
            return fn();
          } catch (e) {
            return undefined;
          }
        };

        const parseJson = () => ({
          ...acc,
          ...JSON.parse(String(curr).replace("GraphQL error: ", "")),
        });

        const getVariable = () => {
          return attempt(() => curr.replace(/"/g, "").match(/\$(.\S*)/)[1]);
        };

        const parseValueAt = () => {
          const root = getVariable();
          const key = curr.match(/value\.(.*);/)[1];

          return {
            ...acc,
            [uniq(compact([root, key])).join(".")]: curr,
          };
        };

        const parseVariable = () => {
          const key = getVariable(curr);

          return {
            ...acc,
            [key]: curr,
          };
        };

        const parseDuplicateKeyError = () => {
          const str = String(curr);

          if (str.includes("E11000 duplicate")) {
            const k = str
              .replace(
                "GraphQL error: E11000 duplicate key error collection:",
                ""
              )
              .split("{")[1]
              .split(":")[0]
              .trim();

            return {
              ...acc,
              [k]: "Duplicate detected. Please enter a unique value.",
            };
          }

          return {
            ...acc,
          };
        };

        return (
          attempt(parseJson) ||
          attempt(parseDuplicateKeyError) ||
          attempt(parseValueAt) ||
          attempt(parseVariable) ||
          acc
        );
      }, {});

      setErrors(newErrorState);
      setErrorObject(newErrorStateObject);

      if (newErrorState) {
        alert(
          "The form failed to save. Please scroll to the bottom of the page to check for highlighted validation issues or messages."
        );
      }
    },
  };
};

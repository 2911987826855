import React from "react";
import { Link } from "gatsby";
import { get, filter } from "lodash";
import ContractForm from "../../forms/contract";
import { ProtectedContext } from "../../components/Protected";
import ReadOnly from "./readOnly";
import DetailPage from "../../components/DetailPage";

const isShowReadyOnly = (contract, type) => {
  const is = (xs) => get(contract, "status") === xs;

  return (
    is("Approved") ||
    (is("Submitted") && type === "recruiter") ||
    (is("Pending") && type !== "administrator")
  );
};

const ContractUpdate = () => {
  const { type } = React.useContext(ProtectedContext);

  return (
    <DetailPage
      trashAuthScope="recruitment"
      namespace="contract"
      renderTitle={(data) =>
        `${get(data, "getContract.template", "Contract")} Form`
      }
      marshal={(payload) => {
        let variables = payload;

        variables.updatedAt = new Date();
        variables.payouts = filter(variables.payouts, (item) => {
          return item.rate && item.recruiter;
        });

        return variables;
      }}
    >
      {({ data, handleSubmit }) => {
        const contract = get(data, "getContract");
        const recruiter = contract?.references?.recruiterId;

        return (
          <>
            {recruiter && (
              <p style={{ marginTop: "-1.5rem", marginBottom: 0 }}>
                Contract created by{" "}
                <Link
                  to={`/flextime/user/${contract?.references?.recruiterId}`}
                >
                  {contract?.references?.recruiterName}
                </Link>{" "}
                (Recruiter/Subvendor)
              </p>
            )}
            {isShowReadyOnly(contract, type) ? (
              <ReadOnly {...contract} />
            ) : (
              <ContractForm {...contract} onSubmit={handleSubmit} />
            )}
          </>
        );
      }}
    </DetailPage>
  );
};

export default ContractUpdate;

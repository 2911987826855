import React from "react";
import { navigate } from "@reach/router";
import CreatePage from "../../components/CreatePage";
import { Formik, Form, ErrorMessage } from "formik";
import Submit from "../../components/Submit";
import ExpandedRadioField from "../../components/ExpandedRadioField";
import DetailPageErrorsConsumer from "../../components/DetailPageErrorsConsumer";

const fields = {
  employ: {
    label: "Subcontractor Placement Form",
    value: "Subcontractor Placement",
    helper: "Use for Contractors that will submit timesheets",
  },
  "employ-temp": {
    label: "Temporary Employee Placement Form",
    value: "Temporary Employee Placement",
    helper: "Use for Temporary Employees that will submit timesheets",
  },
  extend: {
    label: "Contract Extension/Revision Form",
    value: "Contract Extension/Revision",
    helper:
      "Use to extend the employment period or modify a rate for an existing contractor or temporary employee",
  },
  place: {
    label: "Permanent Placement Form",
    value: "Permanent Placement",
    helper: "Use for full-time Permanent Placements",
  },
  payroll: {
    label: "Client Payroll Placement Form",
    value: "Client Payroll Placement",
    helper:
      "Use for Client Payroll placements, who are always non-portal and do not receive Google Forms",
  },
};

export const ContractAdd = () => (
  <CreatePage
    namespace="contract"
    onDone={({ data }) =>
      navigate(`/flextime/contract/${data.createContract.insertedId}`)
    }
  >
    {({ handleSubmit }) => (
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          template: fields.employ.value,
        }}
      >
        {() => (
          <Form>
            <ExpandedRadioField required name="template" {...fields.employ} />
            <ExpandedRadioField
              required
              name="template"
              component="input"
              type="radio"
              {...fields["employ-temp"]}
            />
            <ExpandedRadioField
              required
              name="template"
              component="input"
              type="radio"
              {...fields.payroll}
            />
            <ExpandedRadioField
              required
              name="template"
              component="input"
              type="radio"
              {...fields.place}
            />
            <ExpandedRadioField
              required
              name="template"
              component="input"
              type="radio"
              {...fields.extend}
            />
            <ErrorMessage component="span" name="template" />
            <Submit text="Next" />
            <DetailPageErrorsConsumer />
          </Form>
        )}
      </Formik>
    )}
  </CreatePage>
);

export default ContractAdd;

import React from "react";
import { useParams } from "@reach/router";
import { CONTRACT_EXPORT } from "../../queries/contract";
import { useMutation } from "react-apollo";
import { saveAs } from "file-saver";
import { get } from "lodash";
import { Button, PrintIcon } from "../../components/Print";

const ContractExport = () => {
  const { id } = useParams();
  const [mutate] = useMutation(CONTRACT_EXPORT);

  return (
    <Button
      style={{ margin: 0 }}
      onClick={() => {
        mutate({
          variables: {
            id,
          },
        }).then((res) => {
          const buf = get(res, `data.generateExport.data`);
          const parsed = JSON.parse(buf);
          const a = Buffer.from(parsed.data);
          const uni = new Uint8Array(a);
          saveAs(new Blob([uni]), `ContractExport.xlsx`);
        });
      }}
    >
      <PrintIcon />
      <span>Export to Excel</span>
    </Button>
  );
};

export default ContractExport;

import React from "react";
import { get } from "lodash";
import PayoutForm from "../../forms/payout";
import DetailPage from "../../components/DetailPage";

const PayoutUpdate = () => (
  <DetailPage renderTitle={() => "Placement"} namespace="payout">
    {({ handleSubmit, data }) =>
      get(data, "getPayoutDetail") ? (
        <PayoutForm init={data.getPayoutDetail} action={handleSubmit} />
      ) : null
    }
  </DetailPage>
);

export default PayoutUpdate;

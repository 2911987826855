import React from "react";
import ContractSharedForm from "./shared";
import ContractorBusinessFields from "../../components/ContractorBusinessFields";
import SubcontractorCheckbox from "../../components/SubcontractorCheckbox";
import ExpandedCheckbox from "../../components/ExpandedCheckbox";
import { ProtectedElement } from "../../components/Protected";

const ContractContractorForm = (props) => (
  <ContractSharedForm
    {...props}
    checklist={
      <ProtectedElement rule="moderator">
        <ExpandedCheckbox
          label="Has HST statement been returned?"
          name="hasHstStatement"
        />
        <SubcontractorCheckbox />
      </ProtectedElement>
    }
  >
    <ContractorBusinessFields />
  </ContractSharedForm>
);

export default ContractContractorForm;

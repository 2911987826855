import React from "react";
import { get } from "lodash";
import CompanyForm from "../../forms/company";
import DetailPage from "../../components/DetailPage";

const CompanyDetail = () => (
  <DetailPage renderTitle={() => "Company"} namespace="company">
    {({ handleSubmit, data }) =>
      get(data, "getCompanyDetail") ? (
        <CompanyForm init={data.getCompanyDetail} action={handleSubmit} />
      ) : null
    }
  </DetailPage>
);

export default CompanyDetail;

import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import moment from "moment";
import Grid, { Third } from "../../components/Grid";
import Submit from "../../components/Submit";
import Clear from "../../components/Clear";
import ExpandedField from "../../components/ExpandedField";
import { ProtectedContext } from "../../components/Protected";
import { templateMap } from "../../forms/contract";

const ContractFilter = ({ action, init }) => {
  const auth = React.useContext(ProtectedContext);
  const status =
    // eslint-disable-next-line
    auth?.type !== "administrator"
      ? ["Pending", "Draft", "Approved", "Submitted"]
      : ["Pending", "Approved"];

  const castFromDates = (xs = {}) => {
    const variables = { ...xs };
    if (variables.from)
      variables.from = moment(variables.from).format("YYYY-MM-DD");
    if (variables.to) variables.to = moment(variables.to).format("YYYY-MM-DD");
    return variables;
  };

  const castToDates = (xs = {}) => {
    const variables = { ...xs };
    if (variables.from)
      variables.from = moment(variables.from, "YYYY-MM-DD")
        .add(5, "hours")
        .toISOString();
    if (variables.to)
      variables.to = moment(variables.to, "YYYY-MM-DD")
        .add(5, "hours")
        .toISOString();
    return variables;
  };

  return (
    <Formik
      initialValues={castFromDates(init)}
      enableReinitialize
      onSubmit={(values) => {
        if ("hasBeenDownloaded" in values)
          // otherwise gets dropped from URL
          values.hasBeenDownloaded = String(values.hasBeenDownloaded);

        return action(castToDates(values));
      }}
      render={() => (
        <Form>
          <Grid>
            <Third>
              <ExpandedField label="Search" name="search" />
            </Third>
            <Third>
              <ExpandedField label="Type" name="template">
                <option value="">Select</option>
                {Object.keys(templateMap).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </ExpandedField>
            </Third>
            <Third>
              <ExpandedField label="Status" name="status">
                <option value="">Select</option>
                {status.map((s) => (
                  <option value={s} key={s}>
                    {s}
                  </option>
                ))}
              </ExpandedField>
            </Third>
            <Third>
              <ExpandedField label="From" name="from" type="date" />
            </Third>
            <Third>
              <ExpandedField label="To" name="to" type="date" />
            </Third>
            <Third>
              <ExpandedField
                label="Has been downloaded"
                name="hasBeenDownloaded"
              >
                <option value="">Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </ExpandedField>
            </Third>
          </Grid>
          <Submit text="Filter" />
          <Clear />
        </Form>
      )}
    />
  );
};

ContractFilter.propTypes = {
  init: PropTypes.object,
  action: PropTypes.func,
};

export default ContractFilter;

import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import General from "./general";
import Submit from "../../components/Submit";
import Payout from "./payout";
import { toDate, mapExpiryDate } from "../utils";
import DetailPageErrorsConsumer from "../../components/DetailPageErrorsConsumer";
import InvoicingContacts from "../user/invoicingContacts";
import GuaranteeField from "../../components/GuaranteeField";

const PayoutForm = ({ action, id, init }) => {
  // eslint-disable-next-line
  const initArrayValues = (key) => (Array.isArray(init[key]) ? init[key] : []);
  // eslint-disable-next-line
  const initNumber = (key) => (init[key] ? init[key] : 0);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        accountManager: "",
        candidateSource: "",
        billingCycle: "",
        ...init,
        invoicingContacts: initArrayValues("invoicingContacts"),
        guarantees: mapExpiryDate(initArrayValues("guarantees")),
        payouts: initArrayValues("payouts"),
        startDate: toDate(init.startDate),
        invoiceDate: toDate(init.invoiceDate),
        fee: initNumber("fee"),
        salary: initNumber("salary"),
      }}
      onSubmit={(payload, actions) => {
        let variables = payload;

        action(variables).then(() => {
          actions.resetForm();
          return true;
        });
      }}
      render={({ values }) => (
        <Form>
          <General />
          <GuaranteeField id={id} values={values} />
          <Payout id={id} values={values} />
          <InvoicingContacts id={id} values={values} />
          <Submit text="Save" />
          <DetailPageErrorsConsumer />
        </Form>
      )}
    />
  );
};

PayoutForm.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  init: PropTypes.object,
  data: PropTypes.obj,
};

export default PayoutForm;

import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Field, ErrorMessage } from "formik";
import { get } from "lodash";
import { USER_LIST_DROPDOWN } from "../../queries/user";
import withFieldRepeater from "../../components/withFieldRepeater";
import Group from "../../components/Group";
import OptionsMonth from "../../components/OptionsMonth";
import OptionsYear from "../../components/OptionsYear";
import ExpandedField from "../../components/ExpandedField";

const Payout = ({ idx }) => (
  <Group>
    <Query
      query={USER_LIST_DROPDOWN}
      variables={{ type: "recruiter" }}
      fetchPolicy="network-only"
    >
      {({ data }) => (
        <label>
          Recruiter/Subvendor: *
          <Field component="select" required name={`payouts[${idx}].recruiter`}>
            <option>Select</option>
            {get(data, "getUsersForSelect", [])
              .map((obj) => ({
                label: `${obj.firstName} ${obj.lastName}`,
                value: obj._id,
              }))
              .map((item) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
          </Field>
          <ErrorMessage component="span" name={`payouts[${idx}].recruiter`} />
        </label>
      )}
    </Query>
    <ExpandedField
      name={`payouts[${idx}].amount`}
      required
      label="Amount"
      type="price"
    />
    <label>
      Month: *
      <Field component="select" required name={`payouts[${idx}].month`}>
        <OptionsMonth />
      </Field>
      <ErrorMessage component="span" name={`payouts[${idx}].month`} />
    </label>
    <label>
      Year: *
      <Field component="select" required name={`payouts[${idx}].year`}>
        <OptionsYear />
      </Field>
      <ErrorMessage component="span" name={`payouts[${idx}].year`} />
    </label>
  </Group>
);

Payout.propTypes = {
  idx: PropTypes.number.isRequired,
};

export default withFieldRepeater(
  Payout,
  { title: "Payouts", key: "payouts" },
  {
    recruiter: "",
  }
);

import React from "react";
import { Router } from "@reach/router";
import CompanyList from "./company";
import CompanyAdd from "./company/add";
import CompanyUpdate from "./company/detail";
import PayoutUpdate from "./payout/detail";
import ListPayout from "./payout";
import PayoutAdd from "./payout/add";
import ListUser from "./user";
import AddUser from "./user/add";
import UpdateUser from "./user/detail";
import TimeSheets from "./timesheet";
import AddTimeSheet from "./timesheet/add";
import UpdateTimeSheet from "./timesheet/detail";
import { ProtectedRoute } from "../components/Protected";
import ChangePassword from "./changePassword";
import Contract from "./contract";
import AddContract from "./contract/add";
import UpdateContract from "./contract/detail";
import Tools from "./Tools";
import Logs from "./Logs";

const root = "flextime";
const companyDir = `${root}/company`;
const userDir = `${root}/user`;
const payoutDir = `${root}/payout`;

const Index = () => (
  <Router>
    <TimeSheets path={root} />
    <ProtectedRoute rule="admin" render={Logs} path={`${root}/logs`} />
    <ProtectedRoute rule="moderator" render={Tools} path={`${root}/tools`} />
    <ProtectedRoute rule="admin" render={ListPayout} path={payoutDir} />
    <ProtectedRoute rule="admin" render={PayoutAdd} path={`${payoutDir}/add`} />

    <ProtectedRoute
      rule="recruitment"
      render={AddContract}
      path={`${root}/contract/add`}
    />
    <ProtectedRoute
      rule="recruitment"
      render={UpdateContract}
      path={`${root}/contract/:id`}
    />
    <ProtectedRoute
      rule="recruitment"
      render={Contract}
      path={`${root}/contract`}
    />
    <ProtectedRoute
      rule="admin"
      render={PayoutUpdate}
      path={`${payoutDir}/:id`}
    />
    <ProtectedRoute rule="moderator" render={CompanyList} path={companyDir} />
    <ProtectedRoute
      rule="moderator"
      render={CompanyAdd}
      path={`${companyDir}/add`}
    />
    <ProtectedRoute
      rule="moderator"
      render={CompanyUpdate}
      path={`${companyDir}/:id`}
    />
    <ProtectedRoute rule="moderator" render={ListUser} path={userDir} />
    <ProtectedRoute rule="moderator" render={AddUser} path={`${userDir}/add`} />
    <ProtectedRoute
      rule="moderator"
      render={UpdateUser}
      path={`${userDir}/:id`}
    />
    <ProtectedRoute
      rule="contractor"
      render={AddTimeSheet}
      path={`${root}/add`}
    />
    <UpdateTimeSheet path={`${root}/timesheet/:id`} />
    <ChangePassword path={`${root}/change-password`} />
  </Router>
);

export default Index;

/* eslint-disable complexity */
import React from "react";
import { get, map, size, compact, capitalize } from "lodash";
import { ProtectedElement } from "../../components/Protected";
import { capitalizeSentence } from "../../components/ExpandedField";
import { getIsIncorporatedLabel } from "../../components/SubcontractorCheckbox";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import ContractExport from "./export";

const getBillRateLabel = (str) =>
  String(str).includes("Permanent Placement")
    ? "Permanent placement fee"
    : "Bill rate";

const toPhone = (val) => {
  const removeNonDigits = (v) => String(v).replace(/\W|\D|\s/g, "");

  const printTel = (parts, ext) => {
    if (!parts) return val;

    const [, countryCode, areaCode, officeCode, stationCode] = parts;

    let formatted = "";

    if (countryCode) formatted += `+${countryCode} `;
    if (areaCode) formatted += `(${areaCode}) `;
    if (officeCode) formatted += `${officeCode}-`;
    if (stationCode) formatted += `${stationCode}`;
    if (ext) formatted += ` x${removeNonDigits(ext)}`;
    return formatted;
  };

  if (!val || !val.length) return val;
  const [num, , ext] = val.split(/([a-zA-Z]+)/);

  return printTel(
    removeNonDigits(num).match(
      // eslint-disable-next-line
      /^([+]?\d{1,2}[.-\s]?)?(\d{3})(\d{3})(\d{4})$/
    ),
    ext
  );
};

const toPercent = (xs) => `${Number(xs).toFixed(2)}%`;

const toPostal = (val) => {
  if (!val || val.length) return val;
  const output = val.toUpperCase().replace(/\s+/g, "");

  if (!new RegExp(/^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/).test(output))
    return val;

  return output;
};

const toDate = (xs) =>
  xs && moment(xs).isValid()
    ? moment(xs).utc().format("dddd, MMMM Do, YYYY")
    : "";

const toFixed = (str) => {
  const num = Number(str);
  return (num && !Number.isNaN(num) ? `$${num.toFixed(2)}` : "$0.00").replace(
    // eslint-disable-next-line
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
};

const toBool = (xs) => capitalize(String(xs));

const Table = styled.table`
  table-layout: fixed;

  td,
  th {
    width: 100%;

    a {
      text-decoration: underline;
    }
  }

  tbody {
    background: transparent;

    tr {
      &:nth-child(odd) td {
        background: rgba(255, 255, 255, 0.7);
      }

      &:nth-child(even) td {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
`;

// eslint-disable-next-line
const Row = ({ label, value }) =>
  value ? (
    <tr>
      <td>
        <strong>{capitalizeSentence(label)}</strong>
      </td>
      <td>{value}</td>
    </tr>
  ) : null;

// eslint-disable-next-line
const BooleanRow = ({ value, ...etc }) => (
  <Row {...etc} value={toBool(value || false)} />
);

const ReadOnly = ({
  billingCycle,
  invoicingContacts = [],
  birthday,
  candidateRate,
  clientRate,
  email,
  endDate,
  firstPaymentDate,
  fsContractNum,
  location,
  mailingAddress,
  phone,
  po,
  role,
  startDate,
  registeredAddress,
  tax,
  businessName,
  rateType,
  hst,
  guarantees,
  invoiceDate,
  references,
  payouts,
  notes,
  gm,
  nm,
  candidateSource,
  needsFingerprintReimbursement,
  accountManager,
  hasTimesheetScheduleBeenSent,
  template,
  portalUserType,
  hasVoidCheque,
  hasArticlesOfIncorporation,
  isAddressComplete,
  hasDobBeenProvided,
  hasSinBeenProvided,
  hasTd1,
  hasHstStatement,
  fee,
  isIncorporated,
  feeType,
  hasBeenDownloaded,
}) => {
  const PLACEMENT = "Permanent Placement";
  const SUBCONTRACTOR = "Subcontractor Placement";
  const EMPLOYEE = "Temporary Employee Placement";
  const PAYROLL = "Client Payroll Placement";

  const makeLink = (root) => {
    const text = get(references, `${root}Name`);

    return get(references, `${root}Id`) ? (
      <Link
        to={`/flextime/${root === "company" ? root : "user"}/${get(
          references,
          `${root}Id`
        )}`}
      >
        {text}
      </Link>
    ) : (
      text
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>Contract details</h2>
        <ProtectedElement rule="moderator">
          <ContractExport />
        </ProtectedElement>
      </div>
      {hasBeenDownloaded && (
        <p style={{ color: "#E52225" }}>
          <i>
            ** Note that this contract has already been exported by an
            administrator **
          </i>
        </p>
      )}
      <Table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <Row label="Company" value={makeLink("company")} />
          <Row
            label="Company address"
            value={get(references, "companyAddress")}
          />
          <Row label="Flextime Manager" value={makeLink("manager")} />
          <Row label="Flexstaf Account Manager" value={accountManager} />
          <Row label="Name" value={makeLink("contractor")} />
          <Row label="Email" value={email} />
          <Row label="Phone" value={toPhone(phone)} />
          <Row label="Date of Birth" value={toDate(birthday)} />
          <Row label="Candidate source" value={candidateSource} />
          <Row
            label="Mailing address"
            value={compact([
              get(mailingAddress, "street"),
              get(mailingAddress, "city"),
              get(mailingAddress, "province"),
              get(mailingAddress, "country"),
              toPostal(get(mailingAddress, "postal")),
            ]).join(", ")}
          />
          <Row label="Position/Title" value={role} />
          <Row label="FS contract #" value={fsContractNum} />
          <Row label="Location" value={location} />
          <Row
            label="Flextime Timesheet or submitting client-specific timesheets?"
            value={portalUserType}
          />
          <Row label="Start date" value={toDate(startDate)} />
          <Row label="End date" value={toDate(endDate)} />
          <Row label="Invoice date" value={toDate(invoiceDate)} />
          <Row label="Created by" value={get(references, "recruiterName")} />
          <Row
            label="Recruiter/Subvendor"
            value={get(references, "secondaryRecruiterName")}
          />
          {SUBCONTRACTOR === template && (
            <Row label="Business type" value={isIncorporated} />
          )}
          <Row
            label="Business info/address"
            value={compact([
              businessName,
              get(registeredAddress, "street"),
              get(registeredAddress, "city"),
              get(registeredAddress, "province"),
              get(registeredAddress, "country"),
              toPostal(get(registeredAddress, "postal")),
            ]).join(", ")}
          />
          <Row label="HST number" value={hst} />
          <Row label="HST rate (%)" value={tax} />
          <Row label="Rate type" value={rateType} />
          <Row label="Candidate pay rate" value={toFixed(candidateRate)} />
          <Row label={getBillRateLabel(template)} value={toFixed(clientRate)} />
          {[EMPLOYEE, SUBCONTRACTOR, PAYROLL].includes(template) && (
            <ProtectedElement rule="admin">
              <Row label="Gross margin" value={toFixed(gm)} />
              <Row label="Net margin" value={toFixed(nm)} />
            </ProtectedElement>
          )}
          {template === PLACEMENT && (
            <>
              <Row label="Fee Type" value={feeType} />
              <Row
                label="Fee"
                value={feeType === "Flat" ? toFixed(fee) : toPercent(fee)}
              />
            </>
          )}

          <Row label="PO #" value={po} />
          <Row label="First payment due on" value={toDate(firstPaymentDate)} />
          <Row label="Billing cycle" value={billingCycle} />
        </tbody>
      </Table>
      {[EMPLOYEE, SUBCONTRACTOR, PAYROLL].includes(template) && (
        <>
          <h2>Checklist</h2>
          <Table>
            <thead>
              <tr>
                <th>Question</th>
                <th>Completed</th>
              </tr>
            </thead>
            <tbody>
              <BooleanRow
                label="Needs fingerprint reimbursement?"
                value={needsFingerprintReimbursement}
              />
              <BooleanRow
                label="Has timesheet/payment schedule been sent?"
                value={hasTimesheetScheduleBeenSent}
              />
              <ProtectedElement rule="moderator">
                <BooleanRow
                  label="Has a business bank account or void cheque been returned?"
                  value={hasVoidCheque}
                />
                {template === EMPLOYEE && (
                  <>
                    <BooleanRow
                      label="Is the address complete?"
                      value={isAddressComplete}
                    />
                    <BooleanRow
                      label="Has DOB been provided?"
                      value={hasDobBeenProvided}
                    />
                    <BooleanRow
                      label="Has SIN been provided?"
                      value={hasSinBeenProvided}
                    />
                    <BooleanRow
                      label="Has TD1 Fed/Prov (signed) been returned?"
                      value={hasTd1}
                    />
                  </>
                )}
                {template === SUBCONTRACTOR && (
                  <>
                    <BooleanRow
                      label={getIsIncorporatedLabel(isIncorporated)}
                      value={hasArticlesOfIncorporation}
                    />
                    <BooleanRow
                      label="Has HST statement been returned?"
                      value={hasHstStatement}
                    />
                  </>
                )}
              </ProtectedElement>
            </tbody>
          </Table>
        </>
      )}
      {size(guarantees) ? (
        <>
          <h2>Guarantees</h2>
          <Table>
            <thead>
              <tr>
                <th>Guarantee</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {map(guarantees, (g) => (
                <tr key={g.guarantee}>
                  <td>{g.guarantee}</td>
                  <td>{toDate(g.expiryDate)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}
      {size(invoicingContacts) ? (
        <>
          <h2>Invoicing Contacts</h2>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Title</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {map(invoicingContacts, (inv) => (
                <tr key={inv.name}>
                  <td>{inv.name}</td>
                  <td>{inv.title}</td>
                  <td>{inv.email}</td>
                  <td>{toPhone(inv.phone)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}
      {size(payouts) ? (
        <ProtectedElement rule="admin">
          <h2>Payouts/Commission</h2>
          <Table>
            <thead>
              <tr>
                <th>Recruiter/Subvendor</th>
                <th>Rate</th>
                <th>Month</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              {map(payouts, (pay, idx) => (
                <tr key={pay.recruiter}>
                  <td>
                    <Link href={`/flextime/user/${pay.recruiter}`}>
                      {get(references, `recruiters.${idx}`)}
                    </Link>
                  </td>
                  <td>{toFixed(pay.rate)}</td>
                  <td>{pay.month || "n/a"}</td>
                  <td>{pay.year || "n/a"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ProtectedElement>
      ) : null}
      {notes && (
        <>
          <h2>Notes</h2>
          <p
            style={{
              backgroundColor: "rgba(255,255,255,0.7)",
              padding: "1rem",
            }}
          >
            {notes}
          </p>
        </>
      )}
    </>
  );
};

ReadOnly.propTypes = {
  template: PropTypes.string,
  accountManager: PropTypes.string,
  billingCycle: PropTypes.string,
  birthday: PropTypes.string,
  candidateRate: PropTypes.number,
  clientRate: PropTypes.number,
  email: PropTypes.string,
  endDate: PropTypes.string,
  firstPaymentDate: PropTypes.string,
  fsContractNum: PropTypes.string,
  location: PropTypes.string,
  mailingAddress: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postal: PropTypes.string,
  }),
  phone: PropTypes.string,
  po: PropTypes.string,
  role: PropTypes.string,
  startDate: PropTypes.string,
  registeredAddress: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
    postal: PropTypes.string,
  }),
  hst: PropTypes.string,
  guarantees: PropTypes.arrayOf(
    PropTypes.shape({
      guarantee: PropTypes.string,
      expiryDate: PropTypes.string,
    })
  ),
  invoiceDate: PropTypes.string,
  invoicingContacts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    })
  ),
  references: PropTypes.shape({
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    companyAddress: PropTypes.string,
    manager: PropTypes.string,
    managerId: PropTypes.string,
    contractorId: PropTypes.string,
    contractorName: PropTypes.string,
    recruiterId: PropTypes.string,
    recruiter: PropTypes.string,
  }),
  payouts: PropTypes.arrayOf(
    PropTypes.shape({
      recruiter: PropTypes.string,
      rate: PropTypes.number,
      month: PropTypes.string,
      year: PropTypes.string,
    })
  ),
  hasBeenDownloaded: PropTypes.bool,
  notes: PropTypes.string,
  tax: PropTypes.number,
  businessName: PropTypes.string,
  rateType: PropTypes.string,
  gm: PropTypes.number,
  nm: PropTypes.number,
  candidateSource: PropTypes.string,
  needsFingerprintReimbursement: PropTypes.bool,
  hasTimesheetScheduleBeenSent: PropTypes.bool,
  portalUserType: PropTypes.string,
  hasVoidCheque: PropTypes.bool,
  isAddressComplete: PropTypes.bool,
  hasDobBeenProvided: PropTypes.bool,
  hasSinBeenProvided: PropTypes.bool,
  hasTd1: PropTypes.bool,
  hasHstStatement: PropTypes.bool,
  hasArticlesOfIncorporation: PropTypes.bool,
  fee: PropTypes.number,
  isIncorporated: PropTypes.string,
  feeType: PropTypes.string,
};

export default ReadOnly;

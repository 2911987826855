import React from "react";
import { Query } from "react-apollo";
import { map, get } from "lodash";
import { GET_LOGS } from "../queries/account";
import moment from "moment";

const Logs = () => (
  <Query query={GET_LOGS} fetchPolicy="network-only">
    {({ data }) => {
      return (
        <div style={{ marginTop: "2rem" }}>
          <h1>Logs</h1>
          <p>
            <i>
              <small>Showing maximum of last 250 changes.</small>
            </i>
          </p>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Change stream
                </th>
              </tr>
            </thead>
            <tbody>
              {map(get(data, "getLogs", []), (row) => (
                <tr key={row.timestamp}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "small",
                      width: 350,
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <li>
                        👤 <strong>User</strong>: {row.email}
                      </li>
                      <li>
                        📅 <strong>Modified</strong>:{" "}
                        {moment(row.date).format("LLL")}
                      </li>
                      <li>
                        ⏱️ <strong>Stamped</strong>:{" "}
                        {moment(row.timestamp).format("LLL")}
                      </li>
                    </ul>
                  </td>
                  <td style={{ width: "100%" }}>
                    <pre>{JSON.stringify(JSON.parse(row.log), null, 2)}</pre>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }}
  </Query>
);

export default Logs;

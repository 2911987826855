import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import Submit from "../../components/Submit";

const TimesheetComment = ({ action, statuses, id, commentKey }) => (
  <Formik
    enableReinitialize
    initialValues={{
      status: "",
      [commentKey]: "",
    }}
    onSubmit={(payload, actions) => {
      action({ variables: { ...payload, id } }).then(() => {
        actions.resetForm({});
        return true;
      });
    }}
    render={({ values }) => (
      <Form>
        <label>
          Timesheet action:
          <Field component="select" name="status" required>
            <option>-- Select* --</option>
            {Object.entries(statuses).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Field>
        </label>
        {values.status === "declined" && (
          <label>
            Comments
            <Field component="textarea" name={commentKey} />
          </label>
        )}
        <Submit text="Save" />
      </Form>
    )}
  />
);

TimesheetComment.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  data: PropTypes.obj,
  statuses: PropTypes.array,
  commentKey: PropTypes.string,
};

export default TimesheetComment;

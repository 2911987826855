import React from "react";
import { useField } from "formik";
import ExpandedCheckbox from "./ExpandedCheckbox";

export const getIsIncorporatedLabel = (value) => {
  let label;

  if (value === "Incorporated")
    label = "Have articles of incorporation been returned?";
  if (value === "Partnership")
    label =
      "Has a businsss account (showing business/personal name and HST#) been returned?";
  if (value === "Sole Proprietorship")
    label =
      "Have business/personal CRA supporting registration document(s) been returned?";

  return label;
};

const SubcontractorCheckbox = () => {
  const [{ value }] = useField("isIncorporated");
  const label = getIsIncorporatedLabel(value);

  return label ? (
    <ExpandedCheckbox label={label} name="hasArticlesOfIncorporation" />
  ) : null;
};

export default SubcontractorCheckbox;

import React, { Component } from "react";
import { Mutation } from "react-apollo";
import Alert from "../../components/Alert";
import UserTypes from "../../../static/users.json";
import UserForm from "../../forms/user";
import { ProtectedContext } from "../../components/Protected";

const translate = (xs) => {
  if (xs === "Recruiter") return "Recruiter/Subvendor";
  return xs;
};

export default () => {
  const roles = { ...UserTypes };
  const auth = React.useContext(ProtectedContext);

  if (auth?.type === "moderator") {
    delete roles.administrator;
    delete roles.recruiter;
  }

  return Object.entries(roles).map((entry, i) => (
    <option key={i} value={entry[0]}>
      {translate(entry[1])}
    </option>
  ));
};

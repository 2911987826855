import React from "react";
import { useLocation, Link } from "@reach/router";
import styled from "styled-components";

const Button = styled(Link)`
  background: transparent;
  font-weight: 800;
  margin-left: 1rem;
  text-decoration: underline;
  color: black;
`;

const Clear = () => <Button to={useLocation().pathname || "../"}>Clear</Button>;

export default Clear;

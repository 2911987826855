import React from "react";
import { size, map } from "lodash";
import Alert from "./Alert";

const replaceGraphQlJson = (str) =>
  String(str).startsWith('GraphQL error: {"')
    ? "Server validation failed. Please check the fields above for error messages."
    : str;

// eslint-disable-next-line
export default ({ errors }) => {
  const [state, setState] = React.useState(false);
  const toggle = () => setState(!state);

  return size(errors) ? (
    <Alert timeout={false}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          Could not save this form: the server encountered errors. Please check
          the form fields for inline messages; this is likely a validation
          problem.
        </div>
        <button
          onClick={toggle}
          style={{
            background: "transparent",
            color: "inherit",
            whiteSpace: "nowrap",
          }}
        >
          <span
            style={{
              display: "inline-block",
              transform: "rotate(90deg)",
            }}
          >
            {state ? "‹" : "›"}
          </span>{" "}
          Details
        </button>
      </div>
      {state && (
        <ul>
          {map(errors, (err, idx) => (
            <li key={`graphql-error-${idx}`}>
              {replaceGraphQlJson(err)}; Check form above for validation errors.
            </li>
          ))}
        </ul>
      )}
    </Alert>
  ) : null;
};

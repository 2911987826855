import React from "react";
import ExpandedField from "../../components/ExpandedField";
import PermanentPlacementFeeField from "../../components/PermanentPlacementFeeField";
import Grid, { Half } from "../../components/Grid";
import { UserCompanyList } from "../user/company";
import CandidateSourceField from "../../components/CandidateSourceField";
import BillingCycleField from "../../components/BillingCycleField";

const PayoutGeneral = () => (
  <>
    <Grid>
      <Half>
        <ExpandedField label="First name" required name="firstName" />
      </Half>
      <Half>
        <ExpandedField label="Last name" required name="lastName" />
      </Half>
      <Half>
        <UserCompanyList />
      </Half>
      <Half>
        <ExpandedField
          label="FlexStaf Account Manager"
          type="text"
          name="accountManager"
        />
      </Half>
      <Half>
        <CandidateSourceField />
      </Half>
      <Half>
        <ExpandedField label="Position/Title" name="role" />
      </Half>
      <Half>
        <ExpandedField label="Invoice date" type="date" name="invoiceDate" />
      </Half>
      <Half>
        <ExpandedField label="Start date" type="date" name="startDate" />
      </Half>
      <Half>
        <ExpandedField label="Salary ($)" type="price" name="salary" />
      </Half>
      <Half>
        <BillingCycleField />
      </Half>
      <PermanentPlacementFeeField />
    </Grid>
    <ExpandedField label="Notes" name="notes" component="textarea" rows={15} />
  </>
);

export default PayoutGeneral;

import React from "react";
import PropTypes from "prop-types";
import { isObject, isNumber, isUndefined, size } from "lodash";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import Add from "../components/Add";
import { ProtectedElement } from "../components/Protected";

const makeQueryString = (xs) =>
  isObject(xs)
    ? Object.entries(xs)
        .reduce((acc, [key, value]) => {
          if (
            (size(value) || isNumber(value)) &&
            !isUndefined(value) &&
            value !== 0
          )
            acc.push([key, encodeURIComponent(value)].join("="));
          return acc;
        }, [])
        .join("&")
    : "";

const getQueryParams = (str) => {
  const out = {};
  const searchParams = new URLSearchParams(str);

  searchParams.forEach(function (value, key) {
    // eslint-disable-next-line
    out[key] = decodeURIComponent(value);

    // eslint-disable-next-line
    if (!Number.isNaN(Number(out[key]))) {
      // eslint-disable-next-line
      out[key] = Number(out[key]);
    }

    // eslint-disable-next-line
    if (["true", "false"].includes(out[key]))
      // eslint-disable-next-line
      out[key] = out[key] === "true";
  });

  return out;
};

export default (Comp, Filter, args) => {
  const FilterWrapper = (props) => {
    const location = useLocation();
    const base = String(args.base).endsWith("?")
      ? args.base.slice(0, -1)
      : args.base;

    const appendToBase = (xs, char) => [base, xs].join(char);
    const getVariables = () => getQueryParams(location.search);

    const init = location.search ? getVariables() : args.init || {};
    const { skip = 0 } = init;

    const isFirst = () => !init.skip;
    const incrementSkipValue = (int) => (isNumber(skip) ? skip + int : 0);

    const handleNavigation = (args) =>
      navigate(appendToBase(makeQueryString(args), "?"));

    const handleNavigationByIncrementingSkip = (skipOffsetValue) => () =>
      handleNavigation({
        ...init,
        skip: incrementSkipValue(skipOffsetValue),
      });

    return (
      <main>
        <h1>{args.title}</h1>
        <ProtectedElement rule={args.rule}>
          <Add href={appendToBase("add", "/")}>Create {args.title}</Add>
        </ProtectedElement>
        <h3 style={{ marginBottom: 0 }}>Filter Results</h3>
        <Filter
          action={(args) =>
            handleNavigation({
              ...args,
              skip: 0,
            })
          }
          clear={() =>
            navigate(base, {
              replace: true,
            })
          }
          init={init}
        />
        <Comp
          {...props}
          getVariables={getVariables}
          isFirst={isFirst}
          prev={handleNavigationByIncrementingSkip(-1)}
          next={handleNavigationByIncrementingSkip(1)}
        />
      </main>
    );
  };

  return FilterWrapper;
};

export const types = {
  getVariables: PropTypes.func,
  isFirst: PropTypes.func,
  prev: PropTypes.func,
  next: PropTypes.func,
};

import React from "react";
import { capitalize } from "lodash";
import withFilter from "../withFilter";
import List from "../../components/List";
import Filter from "../../forms/user/filter";
import withQuery from "../../components/withQuery";
import ListHead from "../../components/ListHead";
import { toName } from "../timesheet";

const translate = (xs) => {
  if (xs === "payroll") return "Client Payroll";
  return xs === "recruiter" ? "Recruiter/Subvendor" : capitalize(xs);
};

const UserRow = withQuery(
  ({ children, email, type, ...rest }) => (
    <li>
      <div>{toName(rest)}</div>
      <div>{email}</div>
      <div>{translate(type)}</div>
      {children}
    </li>
  ),
  {
    namespace: "user",
    queryKey: "getUsers",
  }
);

export default withFilter(
  (props) => (
    <List>
      <ListHead headers={["Name", "Email", "Role"]} />
      <UserRow {...props} />
    </List>
  ),
  Filter,
  {
    title: "Users",
    base: "/flextime/user?",
    init: { type: "", email: "", active: true },
    rule: "moderator",
  }
);

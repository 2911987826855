import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import moment from "moment";
import * as Yup from "yup";
import Submit from "../../components/Submit";
import Group from "../../components/Group";

const TimeSheetCreate = Yup.object().shape({
  from: Yup.date(),
  end: Yup.date().when("from", (st) => {
    const s = new Date(st);
    return s.toString() !== "Invalid Date"
      ? Yup.date().min(s, 'Must be later than "from" date')
      : false;
  }),
});

const TimesheetApproval = ({ action }) => (
  <Formik
    initialValues={{
      end: "",
      from: "",
    }}
    validationSchema={TimeSheetCreate}
    onSubmit={({ from, end }) => {
      let fromDate = moment.utc(from).startOf("day");
      let toDate = moment.utc(end).startOf("day");
      let logs = [];

      while (fromDate.diff(toDate) <= 0) {
        let current = fromDate.clone().toDate();
        fromDate.add(1, "days");
        logs.push({
          date: moment.utc(current).format("MM-DD-YYYY"),
          hours: null,
        });
      }

      action({
        variables: {
          logs,
        },
      });
    }}
    render={() => (
      <Form>
        <Group>
          <label>
            From Date
            <Field name="from" type="date" required />
          </label>
          <label>
            End Date
            <Field name="end" type="date" required />
            <ErrorMessage component="span" name="end" />
          </label>
        </Group>
        <Submit text="Generate" />
      </Form>
    )}
  />
);

TimesheetApproval.propTypes = {
  action: PropTypes.func,
};

export default TimesheetApproval;

import React from "react";
import withFilter from "../withFilter";
import List from "../../components/List";
import Filter from "../../forms/company/filter";
import withQuery from "../../components/withQuery";
import ListHead from "../../components/ListHead";

const CompanyRow = withQuery(
  ({ children, name }) => (
    <li>
      <div>{name}</div>
      {children}
    </li>
  ),
  {
    namespace: "company",
    queryKey: "getCompanies",
  }
);

export default withFilter(
  (props) => (
    <List>
      <ListHead headers={["Name"]} />
      <CompanyRow {...props} />
    </List>
  ),
  Filter,
  {
    title: "Companies",
    base: "/flextime/company?",
    init: { search: "" },
    rule: "moderator",
  }
);

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Icon from './Icon';
import withMedia from './withMedia';

const Button = styled(Link)`
    align-items: center;
    background: #FFF;
    border-radius: 5px;
    border-left: 5px solid #00982A;
    display: flex;
    color: #1F1F1F;
    overflow: hidden;
    padding: 2rem 8rem 2rem 3rem;
    position: relative;
    text-align: left;
    max-width: 100%;
    width: 250px;

    h2 { margin: 0 }
    ${withMedia('tablet', css`
        padding: 2rem 3rem;
    `)}

    ${withMedia('phone', css`
        box-sizing: border-box;
        display: block;
    `)}
`;

const IconContainer = styled.div`
    background: #CCC;
    border-radius:50%;
    box-sizing: border-box;
    display: inline-block;
    padding: 1rem;
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;

    svg {
        height: 100%;
        width: 100%;
    }
`;

const BackgroundIcon = styled.div`
    position: absolute;
    right: -10%;
    top: 30%;

    ${withMedia('tablet', css`
        display: none;
    `)}
`;

const Add = ({ href, children }) => (
    <Button to={href}>
        <IconContainer>
            <Icon name="Add" />
        </IconContainer>
        <h2>{children}</h2>
        <BackgroundIcon>
            <Icon name="Add" style={{ height: '12rem', width: '12rem', opacity: 0.05 }} />
        </BackgroundIcon>
    </Button>
);

export default Add; 
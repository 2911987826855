import React from "react";
import PropTypes from "prop-types";
import { Query, Mutation } from "react-apollo";
import moment from "moment";
import { get } from "lodash";
import { navigate } from "gatsby";
import {
  TIMESHEET_DETAIL,
  TIMESHEET_UPDATE,
  TIMESHEET_MODERATION,
  TIMESHEET_DELETE,
  TIMESHEET_SYNC,
  GENERATE_BILL,
} from "../../queries/timesheet";
import { TimeSheetApproval } from "../../forms/timesheet";
import InvoiceTotal from "../../components/InvoiceTotal";
import WeeklyLog from "../../components/WeeklyLog";
import TimeSheetForm from "../../forms/timesheet";
import { ProtectedElement } from "../../components/Protected";
import Refresh from "../../components/Refresh";
import ContractorInfo from "../../components/ContractorInfo";
import TimesheetReview from "../../forms/timesheet/review";
import InvoicePDF from "../../components/InvoicePDF";
import Print, { Button as PrintButton } from "../../components/Print";
import Trash from "../../components/Trash";
import LoadingView from "../../components/Loading";
import PageWrapper from "../../components/PageWrapper";
import { pdf } from "@react-pdf/renderer";
import { Lead } from "../../components/Hero";
import { Button } from "../../components/Button";
import Icon from "../../components/Icon";
//= ===============================================================================
// Helpers
//= ===============================================================================

const createPDF = (sheet, invoiceNum, invoice) =>
  pdf(
    <InvoicePDF
      num={invoiceNum}
      invoice={invoice}
      sheet={sheet}
      total={sheet.subtotal}
      rows={sheet.logs}
    />
  ).toBlob();

export const makeInvoiceNumber = (id) =>
  typeof id === "string" ? parseInt(id.slice(0, 6), 16) : "";

export const groupByWeek = (logs) =>
  logs.reduce(
    (acc, current) => {
      if (moment.utc(current.date).day() === 6) acc.push([]);
      acc[acc.length - 1].push(current);
      return acc;
    },
    [[]]
  );

export const showOnStatus = (fn1, fn2) => (Component, match) => {
  const Decorator = (props) => {
    const { status } = props;
    const showMutation = Array.isArray(match)
      ? fn1(match, status)
      : fn2(match, status);

    return showMutation ? <Component {...props} /> : null;
  };

  Decorator.propTypes = {
    status: PropTypes.string.isRequired,
  };

  return Decorator;
};

export const withMutation = (Component, show) => {
  const Decorator = (props) => {
    const Wrapper = withStatus(Component, show);
    const { id, refresh, ...rest } = props;

    return (
      <Mutation mutation={TIMESHEET_UPDATE} refetchQueries={refresh}>
        {(call, resp) => (
          <Wrapper
            {...props}
            handleClick={(status) => () =>
              call({
                variables: {
                  id,
                  status,
                },
              })}
          />
        )}
      </Mutation>
    );
  };

  Decorator.propTypes = {
    id: PropTypes.string.isRequired,
    refresh: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  return Decorator;
};

export const withStatus = showOnStatus(
  (a, b) => a.includes(b),
  (a, b) => a === b
);

export const withoutStatus = showOnStatus(
  (a, b) => !a.includes(b),
  (a, b) => a !== b
);

//= ===============================================================================
// Components
//= ===============================================================================

const PreReview = withMutation(
  ({ handleClick }) => (
    <React.Fragment>
      <Lead>
        Please <u>review your timesheet entries</u>. To make changes, click
        "Edit" below to return to the editor. Once ready, click "Submit" to send
        for review.
      </Lead>
      <Button onClick={handleClick("draft")} primary>
        Edit Entries
      </Button>
      <Button onClick={handleClick("review")} primary>
        Submit
      </Button>
    </React.Fragment>
  ),
  "prereview"
);

const Review = withStatus(
  ({ id, refresh }) => (
    <ProtectedElement rule="moderator">
      <TimeSheetUpdate mutation={TIMESHEET_MODERATION} refetchQueries={refresh}>
        {(query) => (
          <div>
            <h2 style={{ marginTop: "4rem" }}>Action Required</h2>
            <Lead>
              The worker has submitted timesheets for review. To return to
              editting, send this timesheet back to pre-review. Otherwise,
              continue to pending for manager approval.
            </Lead>
            <TimesheetReview id={id} action={query} />
          </div>
        )}
      </TimeSheetUpdate>
    </ProtectedElement>
  ),
  "review"
);

const PendingAdmin = withStatus(
  ({ id, refresh }) => (
    <ProtectedElement rule="admin">
      <Mutation
        mutation={TIMESHEET_SYNC}
        variables={{ id }}
        refetchQueries={refresh}
      >
        {(query, { loading }) => (
          <div>
            <LoadingView show={loading} />
            <Refresh click={query} />
          </div>
        )}
      </Mutation>
    </ProtectedElement>
  ),
  "pending"
);

export const TimeSheetUpdate = ({ children, ...etc }) => (
  <Mutation {...etc}>
    {(query, { loading, error }) => {
      if (error) return <p>Something went wrong. Please refresh the page.</p>;
      if (loading) return <LoadingView show={loading} />;
      return children(query);
    }}
  </Mutation>
);

TimeSheetUpdate.propTypes = {
  children: PropTypes.func,
};

const PendingManager = withStatus(
  ({ id, refresh }) => (
    <ProtectedElement rule="managerGroup">
      <TimeSheetUpdate mutation={TIMESHEET_MODERATION} refetchQueries={refresh}>
        {(query) => (
          <div>
            <h2 style={{ marginTop: "4rem" }}>Action Required</h2>
            <TimeSheetApproval id={id} action={query} />
          </div>
        )}
      </TimeSheetUpdate>
    </ProtectedElement>
  ),
  "pending"
);

const Total = withStatus(
  ({ taxOnApproval, subtotal, total }) => (
    <InvoiceTotal
      hours={total}
      subtotal={parseFloat(subtotal)}
      tax={taxOnApproval}
    />
  ),
  ["approved", "pending"]
);

const StrongComment = ({ children }) => (
  <p style={{ margin: 0 }}>
    <mark>
      <strong>{children}</strong>
    </mark>
  </p>
);

const withStatusDeclined = (fn) => withStatus(fn, "declined");

const TimesheetReviewComment = withStatusDeclined(
  ({ commentOnReview }) =>
    commentOnReview && (
      <StrongComment>FlexStaf Comment: {commentOnReview}</StrongComment>
    )
);

TimesheetReviewComment.propTypes = {
  commentOnReview: PropTypes.string,
};

const TimeSheetComment = withStatusDeclined(
  ({ comment }) =>
    comment && <StrongComment>Manager Comment: {comment}</StrongComment>
);

TimeSheetComment.propTypes = {
  comment: PropTypes.string,
};

const Comment = withoutStatus(
  ({ belongsTo, comment, ...rest }) => (
    <ContractorInfo {...rest} {...belongsTo} />
  ),
  "prereview"
);

export const TimeSheetViewOnly = ({ id, refresh, data: sheet }) => {
  const renderWithTimesheet = (els) => {
    const renderer = (Comp) => <Comp {...sheet} id={id} refresh={refresh} />;
    return Array.isArray(els) ? els.map(renderer) : renderer(els);
  };

  return (
    <main>
      {[
        renderWithTimesheet([Comment, PreReview]),
        groupByWeek(sheet.logs).map((week, i) => (
          <WeeklyLog key={i} rateType={sheet.typeOnApproval} week={week} />
        )),
        renderWithTimesheet([Review, Total, PendingAdmin, PendingManager]),
      ]}
    </main>
  );
};

TimeSheetViewOnly.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  refresh: PropTypes.array.isRequired,
};

export const DeleteTimesheet = ({ refresh, id }) => (
  <Mutation
    mutation={TIMESHEET_DELETE}
    variables={{ id }}
    refetchQueries={refresh}
  >
    {(query, { loading, error }) => {
      if (loading) return <p>Deleting</p>;
      if (error) return <p>Could not delete</p>;

      return (
        <Trash
          func={() => query().then(() => navigate("/flextime/timesheet/add"))}
        />
      );
    }}
  </Mutation>
);

DeleteTimesheet.propTypes = {
  id: PropTypes.string.isRequired,
  refresh: PropTypes.object,
};

const QuickBooksInvoiceLink = ({ id, invoiceType, quickbooksId }) => {
  if (invoiceType !== "subcontractor") return null;

  const billNum = quickbooksId ? `#${quickbooksId}` : "";

  const redirectUserToQuickbooks = (txnId) =>
    //eslint-disable-next-line
    window.open(`https://c35.qbo.intuit.com/app/bill?txnId=${txnId}`);

  return (
    <Mutation mutation={GENERATE_BILL} variables={{ id }}>
      {(query, { loading, error }) => {
        return (
          <PrintButton
            onClick={() => {
              if (quickbooksId) redirectUserToQuickbooks(quickbooksId);
              else
                query()
                  .then(({ data: { generateBill } }) => {
                    redirectUserToQuickbooks(generateBill.quickbooksId);
                  })
                  .catch(() => {
                    alert(
                      "Could not generate a bill. Please ensure that this sub-contractor has a QuickBooks link saved to their profile."
                    );
                  });
            }}
          >
            <Icon name="Upload" />
            <span>QuickBooks Bill {billNum}</span>
          </PrintButton>
        );
      }}
    </Mutation>
  );
};

QuickBooksInvoiceLink.propTypes = {
  id: PropTypes.string.isRequired,
  invoiceType: PropTypes.string.isRequired,
  quickbooksId: PropTypes.string,
};

export const TimeSheetDownloads = withStatus(
  ({ invoiceNum, quickbooksId, ...rest }) => {
    const curryCreatePdf = (isInvoiceDirective) => () =>
      createPDF(rest, invoiceNum, isInvoiceDirective);

    const makeInvoice = curryCreatePdf(true);
    const makeTimesheet = curryCreatePdf(false);

    const TimesheetPdf = () => (
      <Print title="TimeSheet PDF" func={makeTimesheet} />
    );

    return (
      <ProtectedElement rule="editorltd" fallback={<TimesheetPdf />}>
        <TimesheetPdf />
        <Print title="Invoice" func={makeInvoice} />
        <ProtectedElement rule="moderator" fallback={<div />}>
          <QuickBooksInvoiceLink
            invoiceType={get(rest, "belongsTo.type")}
            id={rest._id}
            quickbooksId={quickbooksId}
          />
        </ProtectedElement>
      </ProtectedElement>
    );
  },
  "approved"
);

const UpdateTimeSheet = ({ id }) => (
  <Query query={TIMESHEET_DETAIL} variables={{ id }}>
    {({ loading, data, error }) => {
      if (error) {
        navigate("/flextime");
        return null;
      }
      if (loading || !data) return <LoadingView show={loading} />;

      const sheet = data.getTimeSheetDetail;
      const invoiceNum = makeInvoiceNumber(sheet._id);

      const isEditable = ["draft", "declined"].includes(sheet.status);
      const preset = get(data, "getTimeSheetDetail.belongsTo.hoursInDay");

      const refresh = [
        {
          query: TIMESHEET_DETAIL,
          variables: { id },
        },
      ];

      const Trash = () => <DeleteTimesheet refresh={refresh} id={id} />;

      const ViewOnly = () => (
        <TimeSheetViewOnly id={id} refresh={refresh} data={sheet} />
      );

      return (
        <PageWrapper title={`Timesheet #${invoiceNum}`}>
          <TimeSheetDownloads invoiceNum={invoiceNum} {...sheet} />
          <TimesheetReviewComment {...sheet} />
          <TimeSheetComment {...sheet} />
          {isEditable ? (
            <ProtectedElement rule="editor" fallback={<ViewOnly />}>
              <TimeSheetUpdate
                mutation={TIMESHEET_UPDATE}
                refetchQueries={refresh}
              >
                {(query) => (
                  <TimeSheetForm
                    id={id}
                    init={sheet.logs}
                    action={query}
                    preset={preset}
                  />
                )}
              </TimeSheetUpdate>
            </ProtectedElement>
          ) : (
            <ViewOnly />
          )}
          <ProtectedElement rule="admin" fallback={<div />}>
            <Trash />
          </ProtectedElement>
        </PageWrapper>
      );
    }}
  </Query>
);

UpdateTimeSheet.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UpdateTimeSheet;

import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Base = styled.button`
    background: transparent;
    line-height: 1.5;
    text-transform: uppercase;

    svg { 
        height: 1rem;
        margin-bottom: -0.15rem;
        width: 1rem; 
    }
`;

const PreviousButton = styled(Base)`
    svg {
        transform: scale(-1,1);
    }
`;

export const Previous = props => (<PreviousButton type="button" {...props}><Icon name="Chevron" /> Previous</PreviousButton>);
export const Next = props => (<Base type="button" {...props}>Next <Icon name="Chevron" /></Base>);
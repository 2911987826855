import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import General from "./general";
import Submit from "../../components/Submit";
import DetailPageErrorsConsumer from "../../components/DetailPageErrorsConsumer";

const CompanyForm = ({ action, init }) => (
  <Formik
    enableReinitialize
    initialValues={init}
    onSubmit={(payload, actions) =>
      action(payload).then(() => {
        actions.resetForm();
        return true;
      })
    }
    render={() => (
      <Form>
        <General />
        <Submit text="Save" />
        <DetailPageErrorsConsumer />
      </Form>
    )}
  />
);

CompanyForm.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  init: PropTypes.object,
  data: PropTypes.obj,
};

export default CompanyForm;

import React from "react";
import { Query } from "react-apollo";
import { get, map } from "lodash";
import ExpandedField from "./ExpandedField";
import { USER_LIST_DROPDOWN } from "../queries/user";

const CreatedForField = () => (
  <Query
    query={USER_LIST_DROPDOWN}
    fetchPolicy="network-only"
    variables={{ type: "recruiter" }}
  >
    {({ loading, data }) => (
      <>
        <ExpandedField
          label="Flexstaf Recruiter/Subvendor "
          helper="Select another recruiter/subvendor if you're completing this form on their behalf or the candidate is shared. Otherwise, please select yourself."
          name="createdFor"
          required
        >
          <option value="">{loading ? "Loading..." : "Select"}</option>
          {map(get(data, "getUsersForSelect", []), (obj, i) => (
            <option key={i} value={obj._id.toString()}>
              {obj.firstName} {obj.lastName}
            </option>
          ))}
        </ExpandedField>
      </>
    )}
  </Query>
);

export default CreatedForField;

import React from "react";
import ExpandedCheckbox from "../../components/ExpandedCheckbox";
import ContractSharedForm from "./shared";
import { ProtectedElement } from "../../components/Protected";

const ContractorPayrollForm = (props) => (
  <ContractSharedForm
    {...props}
    checklist={
      <ProtectedElement rule="moderator">
        <ExpandedCheckbox
          label="Is the address complete?"
          name="isAddressComplete"
        />
      </ProtectedElement>
    }
  ></ContractSharedForm>
);

export default ContractorPayrollForm;

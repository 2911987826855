import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Submit from "../components/Submit";

const ChangePasswordForm = ({ action }) => (
  <Formik
    onSubmit={(variables, actions) =>
      action({ variables }).then(() => {
        actions.resetForm();
      })
    }
    validationSchema={Yup.object({
      newPassword: Yup.string().required("A new password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
        .required("Confirmation of password is required"),
    })}
    initialValues={{
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }}
    render={() => (
      <Form>
        <label>
          Current password *
          <Field name="oldPassword" type="string" required />
        </label>
        <label>
          New password *
          <Field name="newPassword" type="string" required />
        </label>
        <label>
          Confirm new password *<br />
          <small>Re-type the password to prevent typos from saving</small>
          <Field name="confirmPassword" type="string" required />
          <ErrorMessage component="span" name="confirmPassword" />
        </label>

        <Submit text="Save changes" />
      </Form>
    )}
  />
);

ChangePasswordForm.propTypes = {
  action: PropTypes.func,
};

export default ChangePasswordForm;

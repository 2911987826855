import React from 'react';
import { navigate } from 'gatsby';
import { Mutation } from 'react-apollo';
import { TIMESHEET_CREATE } from '../..//queries/timesheet';
import TimeSheetCreate from '../../forms/timesheet/create';
import Lead from '../../components/Lead';

const TimeSheetAdd = () => (<Mutation mutation={TIMESHEET_CREATE}>
    {(query, { data, error, loading }) => {
        if (data && data.createTimeSheet) {
            navigate(`/flextime/timesheet/${data.createTimeSheet.insertedId}`);
        }

        return <main>
            <h1>Create a new timesheet</h1>
            <Lead>Select a start and finish date to generate your timesheet template</Lead>
            <TimeSheetCreate action={query} />
        </main>;

    }}

</Mutation>);

export default TimeSheetAdd;
import React from "react";
import { get } from "lodash";
import UserForm from "../../forms/user";
import DetailPage from "../../components/DetailPage";

const UserDetail = () => (
  <DetailPage renderTitle={() => "User Profile"} namespace="user">
    {({ handleSubmit, data }) => (
      <UserForm
        query={handleSubmit}
        role={get(data, "getUser.type")}
        init={get(data, "getUser")}
      />
    )}
  </DetailPage>
);

export default UserDetail;

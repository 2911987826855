import gql from "graphql-tag";

export const PAYOUT_LIST = gql`
  query getPayouts($search: String, $skip: Float, $limit: Float) {
    getPayouts(search: $search, skip: $skip, limit: $limit) {
      _id
      company
      companyName
      firstName
      lastName
    }
  }
`;

export const PAYOUT_DETAIL = gql`
  query getPayoutDetail($id: ID!) {
    getPayoutDetail(id: $id) {
      _id
      company
      companyName
      firstName
      lastName
      clientRate
      payouts {
        amount
        month
        year
        recruiter
      }
      invoicingContacts {
        name
        email
        phone
        title
      }
      guarantees {
        guarantee
        expiryDate
      }
      notes
      role
      salary
      startDate
      fee
      feeType
      invoiceDate
      candidateSource
      accountManager
      billingCycle
    }
  }
`;

export const PAYOUT_CREATE = gql`
  mutation createPayout(
    $firstName: String!
    $lastName: String!
    $company: ID!
    $payouts: [PayoutInput]
    $invoicingContacts: [InvoicingContactInput]
    $notes: String
    $role: String
    $salary: Float
    $startDate: Date
    $guarantees: [GuaranteeInput]
    $invoiceDate: Date
    $fee: Float
    $candidateSource: String
    $feeType: FeeType
    $accountManager: String
    $billingCycle: String
  ) {
    createPayout(
      firstName: $firstName
      lastName: $lastName
      company: $company
      payouts: $payouts
      notes: $notes
      role: $role
      salary: $salary
      fee: $fee
      startDate: $startDate
      guarantees: $guarantees
      invoicingContacts: $invoicingContacts
      invoiceDate: $invoiceDate
      candidateSource: $candidateSource
      feeType: $feeType
      accountManager: $accountManager
      billingCycle: $billingCycle
    ) {
      insertedId
    }
  }
`;

export const PAYOUT_UPDATE = gql`
  mutation updatePayout(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $company: ID!
    $payouts: [PayoutInput]
    $invoicingContacts: [InvoicingContactInput]
    $notes: String
    $role: String
    $salary: Float
    $startDate: Date
    $guarantees: [GuaranteeInput]
    $invoiceDate: Date
    $fee: Float
    $feeType: FeeType
    $candidateSource: String
    $accountManager: String
    $billingCycle: String
  ) {
    updatePayout(
      id: $id
      firstName: $firstName
      lastName: $lastName
      company: $company
      payouts: $payouts
      invoicingContacts: $invoicingContacts
      notes: $notes
      role: $role
      salary: $salary
      startDate: $startDate
      guarantees: $guarantees
      invoiceDate: $invoiceDate
      fee: $fee
      feeType: $feeType
      candidateSource: $candidateSource
      accountManager: $accountManager
      billingCycle: $billingCycle
    ) {
      company
      companyName
      firstName
      lastName
      notes
      clientRate
      invoicingContacts {
        name
        email
        phone
        title
      }
      payouts {
        amount
        month
        year
        recruiter
      }
      guarantees {
        guarantee
        expiryDate
      }
      role
      salary
      startDate
      fee
      feeType
      invoiceDate
      candidateSource
      accountManager
      billingCycle
    }
  }
`;

export const PAYOUT_DELETE = gql`
  mutation deletePayout($id: ID!) {
    deletePayout(id: $id) {
      _id
    }
  }
`;

export const PAYOUT_EXPORT_GENERATION = gql`
  mutation generatePlacementsReport {
    generatePlacementsReport {
      data
    }
  }
`;

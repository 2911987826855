import React from "react";
import PropTypes from "prop-types";
import { navigate } from "@reach/router";

const PageWrapper = ({ children, title }) => (
  <main>
    <div style={{ marginTop: "2rem" }}>
      <button role="link" onClick={() => navigate(-1)}>
        ‹ Back
      </button>
    </div>
    {title && <h1 style={{ marginTop: "1rem" }}>{title}</h1>}
    {children}
  </main>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default PageWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ProtectedElement } from './Protected';
import List from './List';

const getWeeklyHourlyTotal = arr => Number(
    arr.reduce(((acc, obj) => acc + obj.hours),
        0)).toFixed(2);

const getWeeklyRate = arr => Number(
    arr.reduce(((acc, obj) => acc + obj.subtotal),
        0)).toFixed(2);

const WeeklyLog = ({ week, rateType }) => week.length ? (<figure>
    <List>
        <li>

            <div>Date</div>
            <div># of Hours</div>
            <div>Description</div>
            <ProtectedElement rule="admin">
                <>
                    <div>{rateType || 'Hourly'} Rate</div>
                    <div>Daily Total</div>
                </>
            </ProtectedElement>
        </li>

        {week.map((day, t) => (
            <li key={t}>
                <div>{moment.utc(day.date).format('ddd, MMM Do, YYYY')}</div>
                <div>{day.hours} hrs</div>
                <div>{day.description}</div>
                <ProtectedElement rule="admin">
                    <>
                        <div>${day.rate || 'n/a'}</div>
                        <div>${Number(day.subtotal).toFixed(2)}</div>
                    </>
                </ProtectedElement>
            </li>
        ))}

    </List>

    <figcaption>
        <strong>Total Weekly Hours: </strong>
        <mark>{getWeeklyHourlyTotal(week)} hrs</mark>
        <ProtectedElement rule="admin">
            <>
                <span style={{ margin: '0 1rem' }}>/</span>
                <strong>Weekly Total: </strong>
                <mark>${Number(getWeeklyRate(week)).toFixed(2)}</mark>
            </>
        </ProtectedElement>
    </figcaption>

</figure>
) : null;

WeeklyLog.propTypes = {
    rate: PropTypes.number,
    rateType: PropTypes.string,
    hoursInDay: PropTypes.number,
    week: PropTypes.array
};

WeeklyLog.defaultProps = {
    rate: 0,
    week: [{
        hours: 0
    }]
};

export default WeeklyLog;
import React from "react";
import { get, compact } from "lodash";
import moment from "moment";
import withFilter from "../withFilter";
import List from "../../components/List";
import Filter from "../../forms/timesheet/filter";
import withQuery from "../../components/withQuery";
import ListHead from "../../components/ListHead";

export const castToUtcDate = (xs) => moment.utc(xs).format("ddd MMM D");

export const toName = (xs) =>
  compact([get(xs, "firstName"), get(xs, "lastName")]).join(" ");

const TimesheetRow = withQuery(
  ({ children, status, belongsTo, from: fromDate, to: toDate, total }) => (
    <li className={status}>
      <div>{toName(belongsTo)}</div>
      <div>{castToUtcDate(fromDate)}</div>
      <div>{castToUtcDate(toDate)}</div>
      <div>{total} hrs</div>
      <div style={{ textTransform: "uppercase" }}>{status}</div>
      {children}
    </li>
  ),
  {
    namespace: "timesheet",
    queryKey: "getTimeSheets",
  }
);

export default withFilter(
  (props) => (
    <List>
      <ListHead headers={["Name", "From", "To", "Total hours", "Status"]} />
      <TimesheetRow {...props} />
    </List>
  ),
  Filter,
  {
    title: "Timesheets",
    base: "/flextime?",
    init: { date: "", status: "", belongsTo: "" },
    rule: "contractor",
  }
);

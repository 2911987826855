import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ProtectedElement } from './Protected';

const FloatContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    width: 100%;
`;

const Table = styled.table`
    width: 350px;
`;

const InvoiceTotal = ({ hours, tax, subtotal }) => {

    let total = tax ?
        subtotal + (tax / 100) * subtotal :
        subtotal;

    return (<FloatContainer>
        <Table>
            <tbody>
                <tr>
                    <th>Total Hours</th>
                    <td>{Number(hours).toFixed(2)}</td>
                </tr>
                <ProtectedElement rule="admin">
                    <>
                        <tr>
                            <th>Subtotal</th>
                            <td>${Number(subtotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Tax</th>
                            <td>{tax}%</td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>
                                <mark>${Number(total).toFixed(2)}</mark>
                            </td>
                        </tr>

                    </>
                </ProtectedElement>
            </tbody>
        </Table>
    </FloatContainer>
    );
};

InvoiceTotal.propTypes = {
    hours: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    subtotal: PropTypes.number.isRequired
};

InvoiceTotal.defaultProps = {
    tax: 0
};

export default InvoiceTotal;

import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import Submit from "../../components/Submit";
import Clear from "../../components/Clear";

const PayoutFilter = ({ action, init }) => {
  return (
    <Formik
      initialValues={init}
      enableReinitialize
      onSubmit={action}
      render={() => (
        <Form>
          <label style={{ marginTop: "1rem" }}>
            Search by name
            <Field name="search" type="text" />
          </label>
          <Submit text="Search" />
          <Clear />
        </Form>
      )}
    />
  );
};

PayoutFilter.propTypes = {
  init: PropTypes.object,
  action: PropTypes.func,
  clear: PropTypes.func,
};

export default PayoutFilter;

import React from 'react';
import { Mutation } from 'react-apollo';
import ChangePassword from '../forms/changePassword';
import { CHANGE_PASSWORD } from '..//queries/account';
import Alert from '../components/Alert';

const Password = () => (
    <div>
        <h1>Change Your FlexTime Password</h1>
        <Mutation mutation={CHANGE_PASSWORD}>
            {(query, { error, data }) => (<div>
                <ChangePassword action={query} />
                {data && <Alert success>Password successfully changed.</Alert>}
                {error && <Alert>Could not change the password.</Alert>}
            </div>
            )}
        </Mutation>
    </div>
);

export default Password;
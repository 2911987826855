import gql from "graphql-tag";

export const CONTRACT_LIST = gql`
  query getContracts(
    $template: String
    $search: String
    $status: String
    $skip: Float
    $from: Date
    $to: Date
    $hasBeenDownloaded: Boolean
  ) {
    getContracts(
      template: $template
      search: $search
      status: $status
      skip: $skip
      from: $from
      to: $to
      hasBeenDownloaded: $hasBeenDownloaded
    ) {
      _id
      template
      contractor
      firstName
      lastName
      updatedAt
      status
      references {
        contractorName
        companyName
      }
    }
  }
`;

export const CONTRACT_DETAIL = gql`
  query getContract($id: ID!) {
    getContract(id: $id) {
      _id
      portalUserType
      template
      email
      firstName
      lastName
      phone
      contract
      accountManager
      birthday
      company
      manager
      hasBeenDownloaded
      contractor
      mailingAddress {
        street
        city
        province
        postal
        country
      }
      registeredAddress {
        street
        city
        province
        postal
        country
      }
      guarantees {
        guarantee
        expiryDate
      }
      role
      location
      fsContractNum
      startDate
      endDate
      clientRate
      candidateRate
      billingCycle
      invoiceDate
      po
      firstPaymentDate
      needsFingerprintReimbursement
      hasSocialSecurityBeenReceived
      hasTimesheetScheduleBeenSent
      hasBankingBeenSubmitted
      hst
      td1one
      td1e
      status
      payouts {
        recruiter
        rate
        month
        year
      }
      invoicingContacts {
        name
        email
        phone
        title
      }
      notes
      references {
        contractorId
        contractorName
        companyAddress
        companyId
        companyName
        managerId
        managerName
        recruiterId
        recruiterName
        recruiters
        secondaryRecruiterId
        secondaryRecruiterName
      }
      businessName
      tax
      rateType
      gm
      nm
      candidateSource
      manager
      isIncorporated
      isHst
      fee
      feeType
      hasVoidCheque
      isAddressComplete
      hasDobBeenProvided
      hasSinBeenProvided
      hasTd1
      hasHstStatement
      hasArticlesOfIncorporation
      createdFor
    }
  }
`;

export const CONTRACT_CREATE = gql`
  mutation createContract($template: String!) {
    createContract(template: $template) {
      insertedId
    }
  }
`;

export const CONTRACT_UPDATE = gql`
  mutation updateContract(
    $id: ID!
    $email: Email
    $firstName: String
    $lastName: String
    $phone: Phone
    $contract: String
    $birthday: Date
    $accountManager: String
    $company: ID
    $manager: ID
    $contractor: ID
    $invoiceDate: Date
    $mailingAddress: AddressInput
    $registeredAddress: AddressInput
    $role: String
    $location: String
    $fsContractNum: String
    $startDate: Date
    $endDate: Date
    $clientRate: Float
    $portalUserType: String
    $candidateRate: Float
    $billingCycle: String
    $po: String
    $firstPaymentDate: Date
    $needsFingerprintReimbursement: Boolean
    $hasSocialSecurityBeenReceived: Boolean
    $hasTimesheetScheduleBeenSent: Boolean
    $hasBankingBeenSubmitted: Boolean
    $hst: String
    $updatedAt: Date
    $td1one: String
    $td1e: String
    $payouts: [ContractPayoutInput]
    $invoicingContacts: [InvoicingContactInput]
    $notes: String
    $status: String
    $guarantees: [GuaranteeInput]
    $businessName: String
    $tax: Float
    $rateType: String
    $candidateSource: String
    $isHst: String
    $isIncorporated: String
    $hasVoidCheque: Boolean
    $isAddressComplete: Boolean
    $hasDobBeenProvided: Boolean
    $hasSinBeenProvided: Boolean
    $hasTd1: Boolean
    $hasHstStatement: Boolean
    $hasArticlesOfIncorporation: Boolean
    $fee: Float
    $feeType: FeeType
    $createdFor: ID
  ) {
    updateContract(
      id: $id
      email: $email
      accountManager: $accountManager
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      contract: $contract
      invoiceDate: $invoiceDate
      birthday: $birthday
      company: $company
      manager: $manager
      contractor: $contractor
      mailingAddress: $mailingAddress
      registeredAddress: $registeredAddress
      role: $role
      location: $location
      fsContractNum: $fsContractNum
      startDate: $startDate
      endDate: $endDate
      portalUserType: $portalUserType
      clientRate: $clientRate
      candidateRate: $candidateRate
      billingCycle: $billingCycle
      po: $po
      firstPaymentDate: $firstPaymentDate
      needsFingerprintReimbursement: $needsFingerprintReimbursement
      hasSocialSecurityBeenReceived: $hasSocialSecurityBeenReceived
      hasTimesheetScheduleBeenSent: $hasTimesheetScheduleBeenSent
      hasBankingBeenSubmitted: $hasBankingBeenSubmitted
      hst: $hst
      updatedAt: $updatedAt
      td1one: $td1one
      td1e: $td1e
      payouts: $payouts
      notes: $notes
      status: $status
      guarantees: $guarantees
      businessName: $businessName
      tax: $tax
      rateType: $rateType
      candidateSource: $candidateSource
      invoicingContacts: $invoicingContacts
      isHst: $isHst
      isIncorporated: $isIncorporated
      hasVoidCheque: $hasVoidCheque
      isAddressComplete: $isAddressComplete
      hasDobBeenProvided: $hasDobBeenProvided
      hasSinBeenProvided: $hasSinBeenProvided
      hasTd1: $hasTd1
      hasHstStatement: $hasHstStatement
      hasArticlesOfIncorporation: $hasArticlesOfIncorporation
      fee: $fee
      feeType: $feeType
      createdFor: $createdFor
    ) {
      _id
      portalUserType
      template
      email
      firstName
      lastName
      accountManager
      phone
      contract
      birthday
      company
      manager
      contractor
      isHst
      isIncorporated
      hasBeenDownloaded
      mailingAddress {
        street
        city
        province
        postal
        country
      }
      registeredAddress {
        street
        city
        province
        postal
        country
      }
      invoicingContacts {
        name
        email
        phone
        title
      }
      role
      location
      fsContractNum
      startDate
      endDate
      clientRate
      candidateRate
      billingCycle
      po
      firstPaymentDate
      needsFingerprintReimbursement
      hasSocialSecurityBeenReceived
      hasTimesheetScheduleBeenSent
      hasBankingBeenSubmitted
      hst
      fee
      feeType
      invoiceDate
      updatedAt
      td1one
      td1e
      status
      notes
      payouts {
        recruiter
        rate
        month
        year
      }
      guarantees {
        guarantee
        expiryDate
      }
      businessName
      tax
      rateType
      references {
        contractorId
        contractorName
        companyAddress
        companyId
        companyName
        managerId
        managerName
        recruiterId
        recruiterName
        recruiters
        secondaryRecruiterId
        secondaryRecruiterName
      }
      gm
      nm
      candidateSource
      isIncorporated
      hasVoidCheque
      isAddressComplete
      hasDobBeenProvided
      hasSinBeenProvided
      hasTd1
      hasHstStatement
      hasArticlesOfIncorporation

      createdFor
    }
  }
`;

export const CONTRACT_DELETE = gql`
  mutation deleteContract($id: ID!) {
    deleteContract(id: $id) {
      _id
    }
  }
`;

export const CONTRACT_EXPORT = gql`
  mutation generateExport($id: ID!) {
    generateExport(id: $id) {
      data
    }
  }
`;

import React from "react";
import { useFormikContext } from "formik";
import GuaranteeField from "../../components/GuaranteeField";

const Guarantees = () => {
  const { values } = useFormikContext();
  return <GuaranteeField id={values._id} values={values} />;
};

export default Guarantees;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const List = styled.ul`
  display: inline-block;
  list-style: none;
  margin: 0 0 2rem 0;
  padding: 0;
  width: auto;

  li {
    margin: 0;
    padding: 0.5rem;

    &:not(:first-child) {
      border-top: 1px solid #ababab;
    }
  }
`;

export const ListItem = ({ label, value, ...rest }) =>
  value ? (
    <li>
      <strong>{label}: </strong>
      <span {...rest}>{value}</span>
    </li>
  ) : null;

ListItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const ContractorInfo = (props) => (
  <List>
    <ListItem
      label="Status"
      value={props.status}
      style={{ textTransform: "uppercase" }}
    />
    <ListItem label="Name" value={`${props.firstName} ${props.lastName}`} />
    <ListItem
      label="Address"
      value={`${props.street}, ${props.city}, ${props.province}, ${props.country} ${props.postal}`}
    />
    {props.phone && (
      <ListItem
        label="Phone"
        value={<a href={`tel:${props.phone}`}>{props.phone}</a>}
      />
    )}
    <ListItem
      label="Email"
      value={<a href={`mailTo:${props.email}`}>{props.email}</a>}
    />
    <ListItem label="Contract" value={props.contract} />
  </List>
);

ContractorInfo.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  province: PropTypes.string,
  postal: PropTypes.string,
  country: PropTypes.string,
  status: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  contract: PropTypes.string,
};

export default ContractorInfo;

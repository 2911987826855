import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import moment from "moment";
import * as yup from "yup";
import Filter from "./filter";
import { Button } from "../../components/Button";
import TimeSheetApproval from "./approval";
import Submit from "../../components/Submit";
import { Half } from "../../components/Grid";
import Operator from "../../components/Operator";
import { Lead } from "../../components/Hero";
export { Filter, TimeSheetApproval };

const isWeekend = (date) => {
  const day = new Date(date).getDay();
  return day === 6 || day === 0;
};

/**
 * @NOTE
 * Same controls exist at the top and bottom of the form.
 * Had to be extracted to reduce UI redundancy.
 */

const FormSubmissionControls = ({ fn }) => (
  <p>
    <Submit text="Save Draft" />
    <Button primary style={{ marginLeft: "0.5rem" }} onClick={fn}>
      {"Continue"}
    </Button>
  </p>
);

FormSubmissionControls.propTypes = {
  fn: PropTypes.func.isRequired,
};

const AddRowControls = ({ util, int, date, preset, add }) => {
  /**
   * @NOTE
   * Few conditions happening here:
   * If adding, we must increment day and push to last position in array.
   * If deleting, we must decrease day and push to first position in array.
   *
   */
  let momentMethodName = "subtract";
  let arrayMethodName = "unshift";
  let directionalName = "Above";

  if (add) {
    momentMethodName = "add";
    arrayMethodName = "push";
    directionalName = "Below";
  }

  const fn = () => {
    const newDate = moment.utc(date)[momentMethodName](1, "days");
    return util[arrayMethodName]({
      date: newDate,
      hours: isWeekend(newDate) ? 0 : preset,
      description: "",
    });
  };

  return (
    <>
      <Operator style={{ marginRight: 15 }} onClick={() => util.remove(int)}>
        {"Delete Day"}
      </Operator>
      <Operator onClick={fn}>{`Add ${directionalName}`}</Operator>
    </>
  );
};

AddRowControls.propTypes = {
  util: PropTypes.object.isRequired,
  int: PropTypes.number.isRequired,
  date: PropTypes.object.isRequired,
  preset: PropTypes.number.isRequired,
  add: PropTypes.bool.isRequired,
};

const TimeSheetForm = ({ id, init, action, preset }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={TimeSheetValidation}
      initialValues={{ logs: init, status: "draft" }}
      onSubmit={(values) =>
        action({
          variables: {
            status: values.status,
            logs: values.logs.map((obj) => ({
              ...(obj.description && { description: obj.description }),
              hours: obj.hours,
              date: obj.date,
            })),
            id,
          },
        }).then(() =>
          /**
           * @NOTE
           * scroll to the top to emulate window refresh.
           * really, the data just refreshes, though.
           */

          window.scrollTo(0, 0)
        )
      }
      render={({ setFieldValue, submitForm, values }) => {
        const submissionCallback = () => {
          setFieldValue("status", "prereview", false);
          submitForm();
        };

        return (
          <Form>
            <Lead>
              {`
                                Please complete the timesheet below. 
                                You can save your progress as a draft and return to it at any time. 
                                Once a timesheet has been submitted for review, it can no longer be editted.
                            `}
            </Lead>
            <FormSubmissionControls fn={submissionCallback} />

            <FieldArray
              name="logs"
              render={(arrayHelpers) =>
                values.logs.map((obj, i) => (
                  <div key={i}>
                    <h2>
                      {
                        /** title each entry with date */
                        moment.utc(obj.date).format("dddd, MMMM Do, YYYY")
                      }
                      <br />
                      {i === 0 && (
                        <AddRowControls
                          {...obj}
                          util={arrayHelpers}
                          int={i}
                          preset={preset}
                        />
                      )}

                      {i === values.logs.length - 1 && (
                        <AddRowControls
                          {...obj}
                          util={arrayHelpers}
                          int={i}
                          preset={preset}
                          add
                        />
                      )}
                    </h2>
                    <Field disabled type="hidden" name={`logs[${i}].date`} />

                    <label>
                      Hours Worked That Day
                      <br />
                      <small>Leave as 0 for days off</small>
                      <Field type="number" name={`logs[${i}].hours`} />
                      <ErrorMessage
                        component="span"
                        name={`logs[${i}].hours`}
                      />
                    </label>
                    <label>
                      Description of Work <br />
                      <small>Be brief (under 200 characters)</small>
                      <Field
                        component="textarea"
                        name={`logs[${i}].description`}
                      />
                      <ErrorMessage
                        component="span"
                        name={`logs[${i}].description`}
                      />
                    </label>
                    {i !== values.logs.length - 1 ? <hr /> : <br />}
                  </div>
                ))
              }
            />
            <FormSubmissionControls fn={submissionCallback} />
          </Form>
        );
      }}
    />
  );
};

TimeSheetForm.propTypes = {
  init: PropTypes.array,
  id: PropTypes.string.isRequired,
  action: PropTypes.action,
  preset: PropTypes.number,
};

export const TimeSheetValidation = yup.object().shape({
  logs: yup.array(
    yup.object().shape({
      description: yup.string().nullable(),
      hours: yup
        .number()
        .min(0, "Cannot be a negative number")
        .max(24, "Cannot exceed 24 hours!")
        .typeError(
          "Cannot be empty. Days not worked should have a value of 0."
        ),
    })
  ),
});

export default TimeSheetForm;

import React from "react";
import { map } from "lodash";
import PropTypes from "prop-types";

const ListHead = ({ headers }) => (
  <li>
    {map(headers, (header) => (
      <div key={header}>{header}</div>
    ))}
    <div></div>
  </li>
);

ListHead.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
};

export default ListHead;

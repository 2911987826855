import React from "react";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import withMedia from "./withMedia";
import vars from "../lib/styled";

export const Button = styled.button`
  background: ${vars.primary};
  border-radius: 20px;
  color: #fff;
  line-height: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding: 0 1rem;
  text-transform: uppercase;

  svg {
    fill: #fff;
    height: 100%;
    width: 1.5rem;
  }

  span {
    float: right;
  }
`;

export const PrintIcon = () => <Icon name="Upload" />;

const Print = (props) => (
  <Button
    {...props}
    onClick={() =>
      props.func().then((blob) => {
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      })
    }
  >
    <PrintIcon />
    <span>{props.title}</span>
  </Button>
);

export default Print;

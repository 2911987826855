import styled, { css } from "styled-components";
import withMedia from "./withMedia";

export default styled.ul`
  background-color: #fff;
  list-style: none;
  margin: 2rem 0;
  padding: 0;
  max-width: 100%;

  li {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 1.5rem 4rem;
    text-align: left;
    max-width: none;

    ${withMedia(
      "tablet",
      css`
        padding: 1rem;
      `
    )}

    ${withMedia(
      "phone",
      css`
        display: block;
        padding: 1rem 0.25rem;
      `
    )}

        &:not(:last-child):not(:first-child) {
      border-bottom: 0.15rem solid #e7e8ed;
    }

    div {
      padding: 0 1rem;
      width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      ${withMedia(
        "phone",
        css`
          width: auto;

          &:first-child {
            font-weight: bold;
          }
        `
      )}
    }

    &:first-child {
      background-color: #1f1f1f;
      color: #fff;
      font-weight: bolder;
      text-transform: uppercase;

      ${withMedia(
        "phone",
        css`
          display: none;
        `
      )}
    }
  }
`;

export const ViewMore = styled.div`
  text-align: right;

  svg {
    height: 1.5rem;
    margin-right: 0.5rem;
    stroke: #1f1f1f;
    stroke-width: 2px;
    width: 1.5rem;
  }

  ${withMedia(
    "phone",
    css`
      margin-top: 1rem;
      text-align: left;
    `
  )}
`;

import React from "react";
import PropTypes from "prop-types";
import { useParams } from "@reach/router";
import { Mutation } from "react-apollo";
import * as QUERIES from "../queries";

const toUpper = (str) => String(str).toUpperCase();

export const useQueryNamespace = (namespace) => (cmd) =>
  QUERIES[[namespace, cmd].map(toUpper).join("_")];

const DetailPageMutation = ({ children, namespace }) => {
  const { id } = useParams();
  const qn = useQueryNamespace(namespace);

  return (
    <Mutation
      mutation={qn("update")}
      refetchQueries={[
        { query: qn("list") },
        {
          query: qn("detail"),
          variables: {
            id,
          },
        },
      ]}
    >
      {children}
    </Mutation>
  );
};

DetailPageMutation.propTypes = {
  children: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default DetailPageMutation;

import React from "react";
import ExpandedField from "../../components/ExpandedField";
import { Quickbooks } from "../user/invoice";
import Grid, { Full, Half } from "../../components/Grid";
import Group from "../../components/Group";
import AddressFields from "../../components/AddressFields";

const CompanyGeneral = () => (
  <Grid>
    <Half>
      <ExpandedField label="Name" name="name" required />
    </Half>
    <Half>
      <ExpandedField label="Billing cycle" name="billingCycle">
        <option value="">Select</option>
        <option value="Weekly">Weekly</option>
        <option value="Bi-Weekly">Bi-Weekly</option>
        <option value="Monthly">Monthly</option>
        <option value="Bi-Monthly">Bi-Monthly</option>
      </ExpandedField>
    </Half>
    <Full>
      <Quickbooks required />
    </Full>
    <Full>
      <Group>
        <AddressFields />
      </Group>
    </Full>
  </Grid>
);

export default CompanyGeneral;

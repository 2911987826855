import gql from "graphql-tag";

export const TIMESHEET_CREATE = gql`
  mutation createTimeSheet($logs: [TimeSheetEntry]) {
    createTimeSheet(logs: $logs) {
      insertedId
    }
  }
`;

export const TIMESHEET_LIST = gql`
  query getTimeSheets(
    $status: String
    $belongsTo: ID
    $date: Date
    $skip: Float
  ) {
    getTimeSheets(
      status: $status
      belongsTo: $belongsTo
      date: $date
      skip: $skip
    ) {
      _id
      total
      from
      to
      status
      belongsTo {
        firstName
        lastName
      }
    }
  }
`;

export const TIMESHEET_UPDATE = gql`
  mutation updateTimeSheet($id: ID!, $status: String, $logs: [TimeSheetEntry]) {
    updateTimeSheet(id: $id, status: $status, logs: $logs) {
      ok
    }
  }
`;

export const INVOICE_GENERATION = gql`
  mutation generateQuickbooksInvoice(
    $companyId: ID!
    $from: Date!
    $to: EndDate!
    $manager: ID!
    $po: String
    $users: [ID]
  ) {
    generateQuickbooksInvoice(
      companyId: $companyId
      from: $from
      to: $to
      manager: $manager
      users: $users
      po: $po
    ) {
      data
    }
  }
`;

export const TIMESHEET_GENERATEION = gql`
  mutation generateTimesheetBundle(
    $companyId: ID!
    $from: Date!
    $to: EndDate!
    $manager: ID!
  ) {
    generateTimesheetBundle(
      companyId: $companyId
      from: $from
      to: $to
      manager: $manager
    ) {
      data
    }
  }
`;

export const TIMESHEET_INVOICE_SUMMARY = gql`
  mutation generateTimesheetInvoiceSummary($from: Date!, $to: EndDate!) {
    generateTimesheetInvoiceSummary(from: $from, to: $to) {
      data
    }
  }
`;

export const TIMESHEET_MODERATION = gql`
  mutation moderateTimeSheet(
    $id: ID!
    $status: Status!
    $comment: String
    $commentOnReview: String
  ) {
    moderateTimeSheet(
      id: $id
      status: $status
      comment: $comment
      commentOnReview: $commentOnReview
    ) {
      value {
        status
      }
    }
  }
`;

export const COMMISSION_REPORT = gql`
  mutation generateCommissionReport($from: Date!, $to: EndDate!) {
    generateCommissionReport(from: $from, to: $to) {
      data
    }
  }
`;

export const TIMESHEET_SYNC = gql`
  mutation syncTimeSheet($id: ID!) {
    syncTimeSheet(id: $id) {
      ok
    }
  }
`;

export const TIMESHEET_DELETE = gql`
  mutation deleteTimeSheet($id: ID!) {
    deleteTimeSheet(id: $id) {
      ok
    }
  }
`;

export const TIMESHEET_DETAIL = gql`
  query getTimeSheetDetail($id: ID!) {
    getTimeSheetDetail(id: $id) {
      _id
      status
      rateOnApproval
      taxOnApproval
      lastReviewedOn
      typeOnApproval
      quickbooksId
      hoursInDayOnApproval
      subtotal
      tax
      from
      to
      comment
      commentOnReview
      total
      managedBy {
        firstName
        lastName
      }
      belongsTo {
        firstName
        lastName
        title
        email
        contract
        paymentMadeTo
        business
        hst
        street
        country
        phone
        postal
        city
        province
        hoursInDay
        type
      }
      logs {
        date
        hours
        description
        rate
        subtotal
      }
    }
  }
`;

export const GENERATE_BILL = gql`
  mutation generateBill($id: ID!) {
    generateBill(id: $id) {
      quickbooksId
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import Group from "../../components/Group";
import ExpandedField from "../../components/ExpandedField";
import AddressFields from "../../components/AddressFields";
import ExpandedCheckbox from "../../components/ExpandedCheckbox";
import { UserCompanyList } from "../user/company";
import { UserInvoiceDropdown } from "../user/invoice";
import { ProtectedElement } from "../../components/Protected";
import RateTypeField from "../../components/RateTypeField";
import BillingCycleField from "../../components/BillingCycleField";
import LocationField from "../../components/LocationField";
import Commission from "./commission";
import CandidateSourceField from "../../components/CandidateSourceField";
import Invoicing from "./invoicing";
import CreatedForField from "../../components/CreatedForField";
import { size } from "lodash";

const ContractSharedForm = ({
  checklist,
  contractorFields,
  children,
  template,
}) => {
  const [field] = useField("company");
  const templateStr = String(template);
  const lastNameHelper = templateStr.includes("Employee")
    ? "Legal last name only as with Revenue Canada"
    : "";

  return (
    <>
      <Group>
        <h2>Company Information</h2>
        <UserCompanyList />
        <UserInvoiceDropdown
          disabled={!field.value}
          company={field.value}
          active
        />
        <CreatedForField />
      </Group>
      <Group>
        <h2>{String(template).replace("Placement", "")} Billing Information</h2>
        <ExpandedField label="First name" name="firstName" />
        <ExpandedField
          label="Last name"
          name="lastName"
          helper={lastNameHelper}
          emphasize={size(lastNameHelper)}
        />
        <ExpandedField label="Email" name="email" type="email" />
        <ExpandedField label="Phone" name="phone" type="tel" />
        <CandidateSourceField />
        {contractorFields}
        <AddressFields required={false} prefix="mailingAddress" />
      </Group>
      {children}
      <Group>
        <h2>Position Details</h2>
        {!templateStr.includes("Payroll") && (
          <ExpandedField
            label="Flextime Timesheet or submitting client-specific timesheets?"
            name="portalUserType"
            component="select"
          >
            <option value="">Select</option>
            <option value="Flextime Timesheet">Flextime Timesheet</option>
            <option value="Client-Specific Timesheets">
              Client-Specific Timesheets
            </option>
          </ExpandedField>
        )}
        <ExpandedField label="Flexstaf Account Manager" name="accountManager" />
        <ExpandedField label="Position/Title" name="role" />
        <ExpandedField label="FS contract #" name="fsContractNum" />
        <LocationField />
        <ExpandedField label="Start date" name="startDate" type="date" />
        <ExpandedField label="End date" name="endDate" type="date" />
      </Group>
      <Group>
        <h2>Payment</h2>
        <ExpandedField label="Billing rate" name="clientRate" type="price" />
        <ExpandedField
          label="Candidate rate"
          name="candidateRate"
          type="price"
        />
        <ProtectedElement rule="admin">
          <ExpandedField
            disabled
            helper="This field has been disabled because its value is auto-calculated"
            label="Gross margin"
            name="gm"
            type="price"
          />
          <ExpandedField
            disabled
            helper="This field has been disabled because its value is auto-calculated"
            label="Net margin"
            name="nm"
            type="price"
          />
        </ProtectedElement>
        <RateTypeField required={false} />
        <ExpandedField label="PO #" name="po" />
        <ExpandedField
          label="First payment due on"
          name="firstPaymentDate"
          type="date"
        />
        <BillingCycleField />
      </Group>
      <Invoicing />
      <Group>
        <h2>Checklist</h2>
        <div style={{ width: "100%" }}>
          <ProtectedElement rule="recruiter">
            <ExpandedCheckbox
              label="Does this candidate require reimbursement for fingerprint scanning?"
              name="needsFingerprintReimbursement"
            />
          </ProtectedElement>
          <ProtectedElement rule="moderator">
            <ExpandedCheckbox
              label="Has timesheet/payment schedule been sent?"
              name="hasTimesheetScheduleBeenSent"
            />
            <ExpandedCheckbox
              label="Has a business bank account or void cheque been returned?"
              name="hasVoidCheque"
            />
          </ProtectedElement>
          {checklist}
        </div>
      </Group>
      <ProtectedElement rule="admin">
        <h2>Processing</h2>
        <Commission name="payouts" />
      </ProtectedElement>
      <h3>Notes</h3>
      <ExpandedField
        autocomplete="off"
        label=""
        name="notes"
        component="textarea"
        rows={15}
      />
    </>
  );
};

ContractSharedForm.defaultProps = {
  checklist: null,
  contractorFields: null,
  children: null,
};

ContractSharedForm.propTypes = {
  checklist: PropTypes.any,
  contractorFields: PropTypes.any,
  children: PropTypes.any,
  template: PropTypes.string,
};

export default ContractSharedForm;

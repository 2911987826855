import React from "react";

const OptionsMonth = () => (
  <>
    <option>Select</option>
    {[
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((month) => (
      <option value={month} key={month}>
        {month}
      </option>
    ))}
  </>
);

export default OptionsMonth;

import React from "react";
import ExpandedField from "../../components/ExpandedField";
import PermanentPlacementFeeField from "../../components/PermanentPlacementFeeField";
import Group from "../../components/Group";
import Grid from "../../components/Grid";
import { UserCompanyList } from "../user/company";
import { ProtectedElement } from "../../components/Protected";
import Commission from "./commission";
import Invoicing from "./invoicing";
import Guarantees from "./guarantees";
import CandidateSourceField from "../../components/CandidateSourceField";
import CreatedForField from "../../components/CreatedForField";
import BillingCycleField from "../../components/BillingCycleField";
// eslint-disable-next-line
const ContractPermanentForm = ({ status }) => (
  <>
    <Group>
      <h2>Company Information</h2>
      <UserCompanyList />
      <CreatedForField />
    </Group>
    <Group>
      <h2>Placement Information</h2>
      <ExpandedField label="First name" name="firstName" />
      <ExpandedField label="Last name" name="lastName" />
      <ExpandedField label="Position/Title" name="role" />
      <ExpandedField label="FlexStaf Account Manager" name="accountManager" />
      <CandidateSourceField />
    </Group>
    <Group>
      <h2>Terms/Billing Information</h2>
      <ExpandedField label="Start date" name="startDate" type="date" />
      <ExpandedField label="Invoice date" name="invoiceDate" type="date" />
      <ExpandedField label="Salary" name="candidateRate" type="price" />
      <BillingCycleField />
    </Group>
    <Grid>
      <PermanentPlacementFeeField />
    </Grid>

    <Guarantees />
    <Invoicing />
    {status === "Pending" && (
      <ProtectedElement rule="admin">
        <h2>Processing</h2>
        <Commission name="payouts" includeDates />
      </ProtectedElement>
    )}
    <h3>Notes</h3>
    <ExpandedField label="" name="notes" component="textarea" rows={15} />
  </>
);

export default ContractPermanentForm;

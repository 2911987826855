import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const ExpandedRadioField = ({ label, helper, ...props }) => (
  <label style={{ display: "flex" }}>
    <Field component="input" type="radio" {...props} />
    <div style={{ marginLeft: "1rem" }}>
      <strong>{label}</strong>
      <div>{helper}</div>
    </div>
  </label>
);

ExpandedRadioField.propTypes = {
  label: PropTypes.string.isRequired,
  helper: PropTypes.string.isRequired,
};

export default ExpandedRadioField;

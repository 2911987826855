import React from "react";
import ExpandedField from "./ExpandedField";
import Group from "./Group";
import withFieldRepeater from "./withFieldRepeater";

// eslint-disable-next-line
const GuaranteeField = ({ idx }) => (
  <Group>
    <ExpandedField
      label="Guarantee Information"
      name={`guarantees[${idx}].guarantee`}
      component="select"
    >
      <option>Select</option>
      <option>1 month (30 days)</option>
      <option>2 months (60 days)</option>
      <option>3 months (90 days)</option>
      <option>6 months (180 days)</option>
      <option>No guarantee</option>
      <option>Other</option>
    </ExpandedField>
    <ExpandedField
      helper="This field is disabled because it's automated based off the start date and guarantee. After you save the form, a value will appear (when applicable)."
      label="Expiry Date"
      name={`guarantees[${idx}].expiryDate`}
      type="date"
      disabled
    />
  </Group>
);

export default withFieldRepeater(
  GuaranteeField,
  { title: "Guarantees", key: "guarantees" },
  {
    guarantee: "",
    expiryDate: "",
  }
);

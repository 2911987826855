import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { navigate } from "gatsby";
import Trash from "./Trash";
import { ProtectedElement } from "./Protected";

const TrashMutationContainer = ({ rule, mutation, query, path, id }) => {
  const goToPath = () => navigate(path);

  const handleCallback = (fn) => () => {
    try {
      return fn().then(goToPath);
    } catch (e) {
      // noop
    }
  };

  return (
    <ProtectedElement rule={rule}>
      <Mutation
        mutation={mutation}
        variables={{ id }}
        refetchQueries={[{ query }]}
        onCompleted={goToPath}
      >
        {(fn) => <Trash func={handleCallback(fn)} />}
      </Mutation>
    </ProtectedElement>
  );
};

TrashMutationContainer.propTypes = {
  rule: PropTypes.string.isRequired,
  mutation: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default TrashMutationContainer;

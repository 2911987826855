import React from "react";
import PayoutForm from "../../forms/payout";
import CreatePage from "../../components/CreatePage";

export const PayoutAdd = () => (
  <CreatePage namespace="payout">
    {({ handleSubmit }) => (
      <PayoutForm
        init={{
          firstName: "",
          lastName: "",
          company: "",
          payouts: [],
          guarantees: [],
          role: "",
          startDate: "",
          invoiceDate: "",
          salary: 0,
          fee: 0,
          accountManager: "",
        }}
        action={handleSubmit}
      />
    )}
  </CreatePage>
);

export default PayoutAdd;
